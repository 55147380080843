<template>
  <div class="text-dark-blue form-style">
    <div
      class="married-year-form col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
    >
      <StepTitle :title="$t('marriedSince')" :activeStep="7" />
      <b-form-group>
        <label for="date">{{ $t("chooseDateMarriage") }}</label>
        <!-- <b-form-datepicker
            type="date"
            id="date"
            :lang="$i18n.locale"
            v-model="$v.form.marriageDate.$model"
            :state="!$v.form.marriageDate.$error"
            :max="new Date('2023-12-31')"
            :placeholder="$t('date')"
          /> -->
        <!-- <b-form-input
            type="date"
            id="date"
            v-model="$v.form.marriageDate.$model"
            :state="!$v.form.marriageDate.$error"
            :locale="$i18n.locale"
            :max="minYear"
          /> -->
        <v-date-picker
          v-model="$v.form.marriageDate.$model"
          :state="!$v.form.marriageDate.$error"
          :locale="$i18n.locale"
          :min-date="dateValidation()"
          :max-date="new Date()"
          is-required
          :popover="{ visibility: 'click' }"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="form-control"
              :value="inputValue"
              v-on="inputEvents"
            />
          </template>
        </v-date-picker>
        <b-form-invalid-feedback v-if="!$v.form.marriageDate.required">{{
          $t("validateMessages.pleaseYear")
        }}</b-form-invalid-feedback>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import StepTitle from "./base/StepTitle.vue";
import Input from "../base/formElements/Input.vue";
import { validationMixin } from "vuelidate";

const { required } = require("vuelidate/lib/validators");

export default {
  name: "MarriedYearForm",
  components: {
    StepTitle,
    Input,
  },
  data() {
    return {
      form: {},
      minYear: "",
      info: {},
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      marriageDate: {
        required,
      },
    },
  },
  methods: {
    CHECK_STEP() {
      this.$v.form.$touch();
      return !this.$v.form.$anyError;
    },
    dateValidation() {
      var today = new Date(this.info.birthDate);
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear();
      var a = new Date().getFullYear() - yyyy;
      var c = a - 18;
      var newYear = new Date().getFullYear() - c;

      return new Date(newYear, mm, dd);
    },
  },
  props: {
    userPartner: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.form = this.userPartner;
    this.info = this.data;
    this.dateValidation();
  },
};
</script>

<style>
</style>