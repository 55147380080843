<template>
  <div class="text-dark-blue">
    <div class="year-form col-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
      <StepTitle :title="$t('selectYear')" :activeStep="5" />
      <p class="font-size-14">
        {{ $t("questionText") }}
      </p>
      <div class="row mt-5 align-items-center">
        <div class="col-lg-4">
          <form>
            <div
              class="radio-button"
              v-for="(item, index) in taxYears"
              :key="index"
            >
              <input
                type="radio"
                :id="item.year"
                name="year"
                :value="item.year"
                v-model="form.taxYear"
                :disabled="isTaxYear[item.year]"
              />
              <label :for="item.year" class="mr-2 w-100">{{ item.year }}</label>
            </div>
          </form>
        </div>
        <div class="col-lg-8 d-none d-lg-block">
          <p
            class="year-text"
            :style="{
              backgroundColor: selectColorShow ? '#57C6FF' : '',
              color: selectColorShow ? '#fff' : '',
              borderColor: selectColorShow ? '#fff' : '',
            }"
          >
            {{ $t("startTax") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepTitle from "./base/StepTitle.vue";

export default {
  name: "YearForm",
  components: {
    StepTitle,
  },
  data() {
    const year = new Date().getFullYear() - 1;

    return {
      selected: "",
      selectColorShow: false,
      form: {
        taxYear: null,
      },
      isTaxYear: [],
      taxYears: [
        { year: year, hasFile: false },
        { year: year - 1, hasFile: false },
        { year: year - 2, hasFile: false },
        { year: year - 3, hasFile: false },
      ],
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    years: {
      type: Array,
      default: () => [],
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: Number,
      default: "",
    },
  },
  methods: {
    CHECK_STEP() {
      if (this.form.taxYear === null) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.form = this.data;
    if (this.isVisible) this.form.taxYear = null;
    if (this.form.taxYear === null) {
      this.selectColorShow = false;
    } else this.selectColorShow = true;

    this.years.forEach((item) => {
      this.taxYears.forEach((year) => {
        if (item.year === year.year) {
          this.$set(this.isTaxYear, item.year, 1);
        }
      });
    });
    if (this.isVisible) {
      const disabledIndex = [];
      this.taxYears.forEach((tax, i) => {
        this.years.forEach((year) => {
          if (year.year === tax.year) disabledIndex.push(tax.year);
        });
      });
      this.taxYears.forEach((tax, i) => {
        if (!disabledIndex.includes(tax.year)) this.form.taxYear = tax.year;
      });
    }
  },
  watch: {
    "form.taxYear"(val) {
      // this.$router.replace({ query: { year: val, userId: this.userId || "" } });
      if (this.form.taxYear !== null) {
        this.selectColorShow = true;
      }
    },
  },
};
</script>

<style>
</style>