<template>
  <div class="text-dark-blue">
    <div
      class="signature-form col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
    >
      <StepTitle :title="$t('taxPower')" :activeStep="8" />
      <p class="font-size-14">
        {{ $t("taxPowerText") }}
      </p>
      <p class="font-size-14">
        <strong>{{ $t("signatureMessage") }}</strong>
      </p>
      <button
        class="mt-3 font-weight-bold font-size-14 pdf-button"
        style="text-decoration: underline"
        @click="taxReturnDoc"
      >
        {{ $t("showPreview") }}
      </button>
      <div class="mt-5 text-center w-100">
        <Signature
          :signature="form.signatureUri"
          ref="signature"
          @signatureTax="signatureTax"
          :isVisible="isVisible"
        ></Signature>
      </div>
    </div>
    <b-modal id="modal-pdf" centered :no-close-on-backdrop="true" hide-footer>
      <iframe :src="doc" width="100%" height="600px" frameborder="0"></iframe>
    </b-modal>
  </div>
</template>

<script>
import StepTitle from "./base/StepTitle.vue";
import Signature from "../base/signature/index.vue";
import TaxReturnDoc from "../../mixins/TaxReturnDoc";
import { mapGetters } from "vuex";

export default {
  name: "SignatureForm",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      taxVisible: false,
      doc: "",
      emptySignature: "",
      signatureBase64: "",
    };
  },
  computed: {
    ...mapGetters(["getWebView", "getWebViewApp"]),
  },
  components: {
    StepTitle,
    Signature,
  },
  mixins: [TaxReturnDoc],
  methods: {
    async CHECK_STEP() {
      const signature = await this.$refs.signature.GET_SIGNATURE();
      if (signature.file == null) {
        return false;
      }
      this.form.signatureFile = signature.file;
      this.form.signatureUri = signature.uri;
      return true;
    },
    async taxReturnDoc() {
      // var windowReference = window.open();
      var windowReference = null;
      if (!this.getWebViewApp) {
        windowReference = window.open();
      }
      const doc = await this.taxReturn(this.form, this.emptySignature);

      if (windowReference !== null) windowReference.location = doc;
      // windowReference.location = doc;
      if (!this.getWebViewApp) {
        const intrvl = setInterval(() => {
          try {
            if (windowReference.location.href !== doc) {
              windowReference.close();
            }
          } catch (error) {
            windowReference.close();
          }
          if (windowReference.closed) {
            clearInterval(intrvl);
          }
        }, 1000);
      }
    },
    signatureTax(val) {
      this.taxVisible = val;
    },
  },
  async mounted() {
    this.form = this.data;
    this.form.taxIdentifyNo = this.data.taxIdentifyNo || "";
    const a = await this.$refs.signature.GET_SIGNATURE_NOT_REQUİRED();
    this.emptySignature = a.file;
  },
};
</script>

<style lang="scss">
.signature-form {
  position: relative;
  // overflow-x: hidden;

  .pdf-button {
    background-color: transparent;
    border: none;
    position: absolute;
  }
}
#modal-pdf {
  .modal-dialog {
    max-width: 1000px;
  }
  .modal-content {
    height: 100%;
  }
}
</style>