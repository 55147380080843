<template>
  <div class="text-dark-blue form-style">
    <div class="phone-form col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
      <StepTitle :title="$t('confirmIdentity')" :activeStep="2" />
      <p class="font-size-14 mb-5">
        {{ $t("smsCodeText") }}
      </p>
      <Alert :alert="alert" />
      <b-form-group class="phone-input">
        <p class="area-code">( +49 )</p>
        <label for="telephone">{{ $t("phoneNumber") }}</label>
        <b-form-input
          type="text"
          id="telephone"
          inputmode="tel"
          v-model="$v.form.phoneNumber.$model"
          :state="!$v.form.phoneNumber.$error"
          @input="acceptNumber"
          maxlength="16"
        />
        <b-form-invalid-feedback v-if="!$v.form.phoneNumber.required">{{
          $t("validateMessages.pleasePhoneNo")
        }}</b-form-invalid-feedback>
        <b-form-invalid-feedback v-if="!$v.form.phoneNumber.minLength">{{
          $t("validateMessages.phoneNumberLength")
        }}</b-form-invalid-feedback>
      </b-form-group>
      <div class="d-flex align-items-center">
        <font-awesome-icon :icon="faShield" class="font-size-24 mr-2" />
        <p class="font-weight-bold mb-0">
          {{ $t("numberSsl") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import StepTitle from "./base/StepTitle.vue";
import Input from "../base/formElements/Input.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faShield } from "@fortawesome/free-solid-svg-icons";
import { validationMixin } from "vuelidate";
import { controlPhone, sendPhoneCode } from "../../services/modules/User";
import Alert from "../../components/base/alert/Alert.vue";

const { required, minLength } = require("vuelidate/lib/validators");

export default {
  name: "PhoneForm",
  components: {
    StepTitle,
    Input,
    FontAwesomeIcon,
    Alert,
  },
  data() {
    return {
      faShield,
      form: {},
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      phoneNumberType: "",
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      phoneNo: {
        required,
      },
      phoneNumber: {
        required,
        // minLength: minLength(11),
      },
    },
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async CHECK_STEP() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        const response = await controlPhone(this.form.phoneNo);

        if (response.message === "OK") {
          if (response.data.isExist) {
            this.alert.show = true;
            this.alert.variant = "danger";
            this.alert.message = "validateMessages.phoneMatch";
          } else {
            const response = await sendPhoneCode(
              this.form.phoneNo,
              this.$i18n.locale
            );

            if (response.message === "OK") {
              return true;
            }
          }
        } else {
          this.alert.show = true;
          this.alert.variant = "danger";
          this.alert.message = "validateMessages.phoneCodeMatch";
        }
        setTimeout(() => {
          this.alert.show = false;
        }, 2000);
      }
    },
    acceptNumber() {
      let x = this.form.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,12})/);

      this.form.phoneNumber = !x[2] ? x[1] : x[1] + (x[2] ? "-" + x[2] : "");

      this.form.phoneNo = x[0];
    },
  },
  mounted() {
    this.form = this.data;
  },
};
</script>

<style lang="scss">
// input[type="number"] {
//   -moz-appearance: textfield;
// }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.phone-form {
  .area-code {
    position: absolute;
    top: 52%;
    left: 20px;
  }

  .phone-input {
    position: relative;

    input {
      padding-left: 70px;
    }
  }
}
</style>