<template>
  <div class="text-dark-blue form-style">
    <div
      class="confirmation-form col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
    >
      <StepTitle :title="$t('enterSmsCode')" :activeStep="3" />
      <p class="mb-5 font-size-14">
        {{ $t("sentSmsCodeText") }}
      </p>
      <p class="font-size-14 font-weight-bold text-center">
        ( +49 ) {{ form.phoneNumber }}
      </p>
      <b-form>
        <Alert :alert="alert" />
        <div class="row">
          <b-form-group class="col-3" v-for="(code, i) in codes" :key="i">
            <input
              class="w-100"
              :disabled="isLoading"
              :value="code"
              inputmode="numeric"
              @input="(event) => SET_CODE(event, i)"
              @keyup="(event) => SET_BACKSPACE_CODE(event, i)"
              type="number"
              :id="`number${i}`"
            />
          </b-form-group>
          <div v-if="!result">
            <small class="text-danger">{{ $t("wrongCodeInRegister") }}</small>
          </div>
        </div>
      </b-form>
      <p @click="sendCode" class="font-weight-bold text-center sms-code-repeat">
        {{ $t("againSendSms") }}
      </p>
    </div>
  </div>
</template>

<script>
import { sendPhoneCode, sendPhoneSmsCode } from "../../services/modules/User";
import Alert from "../base/alert/Alert.vue";
import Input from "../base/formElements/Input.vue";
import StepTitle from "./base/StepTitle.vue";

export default {
  name: "ConfirmationForm",
  components: {
    StepTitle,
    Input,
    Alert,
  },
  data() {
    return {
      codes: [null, null, null, null],
      result: true,
      form: {},
      isLoading: false,
      alert: {
        show: false,
        variant: "",
        message: "",
      },
    };
  },
  props: {
    data: {
      type: Object,
      default: "",
    },
  },
  methods: {
    RESET_FORM() {
      this.codes.forEach(
        (code, i) => (document.getElementById(`number${i}`).value = "")
      );
    },
    async CHECK_STEP() {
      this.isLoading = true;
      const codes = this.codes
        .map((code, i) => document.getElementById(`number${i}`).value)
        .join("");
      if (codes.length < 4) {
        this.isLoading = false;
        return false;
      }
      const response = await sendPhoneSmsCode(this.form.phoneNo, codes);
      this.result = false;
      if (response.message === "OK") {
        this.result = true;
      } else if (response.message === "NOT_FOUND") {
        this.result = false;
      }
      if (!this.result) this.RESET_FORM();
      this.isLoading = false;
      return this.result;
    },
    async sendCode() {
      const response = await sendPhoneCode(
        this.form.phoneNo,
        this.$i18n.locale
      );

      if (response.message === "OK") {
        this.alert.show = true;
        this.alert.variant = "success";
        this.alert.message = "againSmsCode";
      } else {
        this.alert.show = true;
        this.alert.variant = "danger";
        this.alert.message = "notAgainSmsCode";
      }

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
    },
    SET_BACKSPACE_CODE(event, index) {
      const key = event.key;
      if (
        key === "Backspace" &&
        (event.target.value === "" || event.target.value === null) &&
        index > 0
      ) {
        document.getElementById(`number${index - 1}`).value = "";
        document.getElementById(`number${index - 1}`).focus();
      }
    },
    SET_CODE(event, index) {
      const val = event.target.value;
      const type = event.inputType;
      if (val.length > 1 && type === "insertText") {
        document.getElementById(`number${index}`).value = val.slice(1);
        if (index + 1 < this.codes.length)
          document.getElementById(`number${index + 1}`).focus();
      } else if (index + 1 < this.codes.length && type === "insertText")
        document.getElementById(`number${index + 1}`).focus();
      else if (index > 0 && type === "deleteContentBackward")
        document.getElementById(`number${index - 1}`).focus();
      else this.$emit("nextButton");
    },
  },
  mounted() {
    this.form = this.data;
  },
};
</script>

<style></style>
