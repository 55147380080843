<template>
  <div>
    <router-link
      to="/"
      class="mt-5 ml-4 d-flex align-items-center router-hover"
      v-if="!getWebViewApp"
    >
      <font-awesome-icon class="text-blue mr-2" :icon="faArrowLeft" />
      <p class="text-blue">{{ $t("returnHomePage") }}</p>
    </router-link>
    <font-awesome-icon
      class="text-blue mt-5 ml-4"
      :icon="faChevronLeft"
      @click="returnHomePageAlert"
      style="cursor: pointer"
      v-else
    />
    <div class="container section step-page">
      <div class="progress-wrap" v-if="!isVisibleStep">
        <div
          class="progress"
          :style="`width: ${stepMap[activeStep]}%`"
          id="progress"
        ></div>
        <div
          v-for="(item, index) in componentsInformation"
          :key="index"
          class="d-flex align-items-center justify-content-center flex-column position-relative"
        >
          <div
            class="step"
            :class="{ active: item.step <= stepMap[activeStep] }"
          >
            {{ index + 1 }}
          </div>
          <div
            class="position-absolute text-center d-none d-md-block- d-lg-block"
            style="bottom: -25px; width: 300px"
          >
            {{ $t(item.title) }}
          </div>
        </div>
      </div>
      <div v-if="isLoading || isYearLoading" class="my-4 text-center">
        <b-spinner variant="primary"> </b-spinner>
      </div>
      <div v-else>
        <component
          :is="components[activeStep]"
          ref="steps"
          class="component-height"
          :data="form"
          :userId="userId"
          :userPartner="usersPartners"
          :documentsFiles="documentsFiles"
          @nextButton="nextButton"
          :years="years"
          :isVisible="isVisibleStep"
          @isPaymentVisible="isPaymentVisible"
          :activeStep="activeStep"
        ></component>
      </div>
      <div
        class="d-flex step-action-button"
        :class="
          activeStep === 0 ? 'justify-content-end' : 'justify-content-between'
        "
        v-if="isVisibleStep ? updateComponents.length !== activeStep + 1 : true"
      >
        <button
          type="button"
          class="blue-button"
          v-if="activeStep !== 0"
          @click="backButton"
          :disabled="loading"
        >
          {{ $t("back") }}
        </button>
        <button
          type="button"
          class="blue-button"
          @click="() => (isVisibleStep ? updateYearNextButton() : nextButton())"
          v-if="!visible"
          :disabled="loading"
        >
          <b-spinner variant="light" v-if="loading"></b-spinner>
          <span v-else> {{ $t("next") }}</span>
        </button>
      </div>
    </div>
    <b-modal
      id="modal-center"
      centered
      v-model="modalShow"
      hide-header
      hide-footer
      :no-close-on-backdrop="true"
    >
      <p class="my-4">
        {{ $t("returnHomePageMessage") }}
      </p>
      <div class="row justify-content-end mr-2">
        <button class="gray-button mr-2" @click="modalShow = false">
          {{ $t("giveUp") }}
        </button>
        <button class="blue-button" @click="$router.push('/')">
          {{ $t("confirm") }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RegisterForm from "@/components/register/RegisterForm.vue";
import PhoneForm from "@/components/register/PhoneForm.vue";
import ConfirmationForm from "../components/register/ConfirmationForm.vue";
import PersonForm from "../components/register/PersonForm.vue";
import YearForm from "../components/register/YearForm.vue";
import MarriedStatusForm from "../components/register/MarriedStatusForm.vue";
import MarriedYearForm from "../components/register/MarriedYearForm.vue";
import DocumentsForm from "../components/register/DocumentsForm.vue";
import SuccessMessage from "../components/register/SuccessMessage.vue";
import SignatureForm from "../components/register/SignatureForm.vue";
import PartnerForm from "../components/register/PartnerForm.vue";
import Payment from "../components/register/Payment.vue";
import {
  getUserInfo,
  getYears,
  putRegisterUserInfo,
  completeRegister,
  registrationUser,
} from "../services/modules/User";
import SignatureFormMarried from "../components/register/SignatureFormMarried.vue";
import { mapGetters } from "vuex";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowLeft, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ChildrenForm from "../components/register/ChildrenForm.vue";
import WorkPlaceAddress from "../components/register/WorkPlaceAddress.vue";
import Swal from "sweetalert2";

export default {
  components: {
    RegisterForm,
    PhoneForm,
    ChildrenForm,
    ConfirmationForm,
    PersonForm,
    YearForm,
    MarriedStatusForm,
    MarriedYearForm,
    DocumentsForm,
    SuccessMessage,
    SignatureForm,
    PartnerForm,
    SignatureFormMarried,
    Payment,
    FontAwesomeIcon,
    WorkPlaceAddress,
  },
  data() {
    return {
      routeChange: false,
      activeStep: 0,
      isLoading: false,
      isYearLoading: false,
      faArrowLeft,
      faChevronLeft,
      modalShow: false,
      stepMap: [0, 8.3, 16.6, 25, 38, 50, 67, 75, 87.5, 94, 100],
      //stepMap: [0, 33, 66, 100],
      components: [
        RegisterForm,
        PhoneForm,
        ConfirmationForm,
        PersonForm,
        YearForm,
        WorkPlaceAddress,
        SignatureForm,
        MarriedStatusForm,
        ChildrenForm,
        DocumentsForm,
        Payment,
        SuccessMessage,
      ],
      updateComponents: [
        YearForm,
        WorkPlaceAddress,
        SignatureForm,
        MarriedStatusForm,
        ChildrenForm,
        DocumentsForm,
        Payment,
        SuccessMessage,
      ],
      componentsInformation: [
        {
          title: "register",
          step: 0,
        },
        {
          title: "personalInfo",
          step: 25,
        },
        {
          title: "selectYear",
          step: 50,
        },
        {
          title: "marriage",
          step: 75,
        },
        {
          title: "documents",
          step: 100,
        },
      ],
      form: {
        email: null,
        password: null,
        phoneNo: null,
        name: null,
        surname: null,
        birthDate: null,
        gender: 1,
        address: {
          street: null,
          apartmentNo: null,
          postalCode: null,
          city: null,
        },
        workPlaceAddress: {
          companyName: "",
          street: "",
          no: "",
          postalCode: "",
          homeOfficeDayCount: 0,
          city: "",
        },
        iban: "",
        taxIdentifyNo: "",
        taxYear: "2022",
        marriageStatus: "1",
        phoneNumber: null,
        discountCode: "",
        discountCodeId: null,
        discountPrice: "",
        discount: 0,
        childrenStatus: false,
        userChilds: [],
        isPartnerPayment: false,
        isCompanyCode: false,
      },
      usersPartners: {
        name: null,
        surname: null,
        marriageDate: null,
        birthDate: null,
        address: {
          street: null,
          apartmentNo: null,
          postalCode: null,
          city: null,
        },
        iban: "",
        taxIdentifyNo: "",
        addressShow: true,
      },
      userId: null,
      documentsFiles: [
        {
          id: 1,
          title: "taxCertificate",
          files: [],
          step: 1,
          value: "IncomeTaxCertificate",
          info: "taxInfo",
          note: "",
          noteStatus: false,
        },
        {
          id: 2,
          title: "investmentIncome",
          files: [],
          step: 1,
          value: "InvestmentIncome",
          info: "incomeInfo",
          note: "",
          noteStatus: false,
        },
        {
          id: 3,
          title: "advertisingCosts",
          files: [],
          step: 1,
          value: "AdvertisingCosts",
          info: "expensesInfo",
          note: "",
          noteStatus: false,
        },
        {
          id: 4,
          title: "movingCosts",
          files: [],
          step: 1,
          value: "MovingCosts",
          info: "movingCostsInfo",
          note: "",
          noteStatus: false,
        },
        {
          id: 5,
          title: "insurances",
          files: [],
          step: 2,
          value: "Fuses",
          info: "insurancesInfo",
          note: "",
          noteStatus: false,
        },
        {
          id: 6,
          title: "donations",
          files: [],
          step: 2,
          value: "Donations",
          info: "contributionsInfo",
          note: "",
          noteStatus: false,
        },
        {
          id: 8,
          title: "revenuesFromAbroad",
          files: [],
          step: 2,
          value: "RevenuesFromAbroad",
          info: "revenuesFromAbroadInfo",
          note: "",
          noteStatus: false,
        },
        {
          id: 9,
          title: "other",
          files: [],
          step: 2,
          value: "Other",
          info: "otherInfo",
          note: "",
          noteStatus: false,
        },
      ],
      loading: false,
      isVisibleStep: false,
      years: [],
      visible: false,
    };
  },
  methods: {
    returnHomePageAlert() {
      this.modalShow = true;
    },
    async registerUser() {
      this.loading = true;
      const response = await registrationUser(
        this.form,
        this.usersPartners,
        this.$i18n.locale
      );
      if (response.message === "OK") {
        localStorage.setItem("userId", response.data);
        this.userId = response.data;
        this.$store.commit("SET_AUTO_LOGIN", {
          email: this.form.email,
          password: this.form.password,
        });
        this.activeStep += 1;
      }
      this.loading = false;
      return;
    },
    async updateRegisterUser() {
      this.loading = true;
      const response = await putRegisterUserInfo(
        this.userId,
        this.form,
        this.usersPartners
      );

      if (response.message === "OK") {
        this.activeStep += 1;
      }
      this.loading = false;
      return;
    },
    backButton() {
      this.activeStep -= 1;
      this.visible = false;

      if (this.getWebView) {
        this.$router.replace({ query: { step: this.activeStep } });
      }
    },
    alertDocumentForm() {
      return new Promise((resolve) => {
        Swal.fire({
          title: `${this.$t("info")}`,
          text: `${this.$t("lastCallForUpload")}`,
          icon: "info",
          iconColor: "#57c6ff",
          showCancelButton: true,
          confirmButtonColor: "#57c6ff",
          cancelButtonColor: "#f43958",
          confirmButtonText: `${this.$t("continue")}`,
          cancelButtonText: `${this.$t("uploadFile")}`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            return resolve(true);
          } else {
            return resolve(false);
          }
        });
      });
    },
    async nextButton() {
      this.loading = true;
      const valid = await this.$refs.steps.CHECK_STEP();

      if (!valid) {
        this.loading = false;
        return;
      }
      //if (this.activeStep === 3) {
      if (this.components[this.activeStep].name === "DocumentForm") {
        if (!(await this.alertDocumentForm())) return this.loading = false;
        //istek at
        const response = await completeRegister(
          this.userId,
          this.form.taxYear,
          this.form.isPartnerPayment
        );
        if (response.message === "OK") {
          return this.$router.push("/complete-register");
        } else {
          Swal.fire({
            title: `${this.$t("failed")}`,
            text: `${this.$t("noCompleteCustomer")}`,
            icon: "error",
            confirmButtonText: `${this.$t("ok")}`,
          });
        }
      } else if (this.activeStep === 8 && this.form.marriageStatus != 2) {
        if (this.userId != null) {
          await this.updateRegisterUser();
        } else {
          await this.registerUser();
        }
      } else if (this.activeStep === 11) {
        if (this.userId != null) {
          await this.updateRegisterUser();
        } else {
          await this.registerUser();
        }
      } else {
        this.activeStep += 1;
        this.loading = false;
      }
      if (this.getWebView) {
        this.$router.push({ query: { step: this.activeStep } });
      }
    },
    async updateYearNextButton() {
      this.loading = true;
      const valid = await this.$refs.steps.CHECK_STEP();
      if (!valid) {
        this.loading = false;
        return;
      }
      if (this.components[this.activeStep].name === "DocumentForm") {
        //istek at
        const response = await completeRegister(
          this.userId,
          this.form.taxYear,
          this.form.isPartnerPayment
        );
        if (response.message === "OK") {
          return this.$router.push("/complete-register");
        } else {
          Swal.fire({
            title: `${this.$t("failed")}`,
            text: `${this.$t("noCompleteCustomer")}`,
            icon: "error",
            confirmButtonText: `${this.$t("ok")}`,
          });
        }
      } else if (this.activeStep === 4 && this.form.marriageStatus != 2) {
        await this.updateRegisterUser();
      } else if (this.activeStep === 7) {
        await this.updateRegisterUser();
      } else {
        this.activeStep += 1;
        this.loading = false;
      }
      if (this.getWebView) {
        this.$router.push({ query: { step: this.activeStep } });
      }
    },
    async userInfo() {
      this.isLoading = true;
      const response = await getUserInfo(this.activeUser.id);

      if (response.message === "OK") {
        this.form = {
          ...response.data,
          childrenStatus: response.data.userChilds.length > 0 ? true : false,
          isPartnerPayment: false,
          workPlaceAddress: {
            companyName: "",
            street: "",
            no: "",
            postalCode: "",
            city: "",
            homeOfficeDayCount: 0,
          },
        };
        // this.form.workPlaceAddress = {
        //   companyName: "",
        //   street: "",
        //   no: "",
        //   postalCode: "",
        //   city: "",
        // };
        this.form.phoneNo = response.data.phoneNo.substring(
          3,
          response.data.phoneNo.length
        );
        if (this.isVisibleStep && response.data.marriageStatus === 2) {
          this.usersPartners = {
            ...response.data.userPartner,
            addressShow: true,
            marriageDate:
              response.data.userPartner?.marriageDate?.split("T")[0],
          };
        }
      }
      this.isLoading = false;
    },
    async yearsList() {
      this.isYearLoading = true;
      const response = await getYears(this.activeUser.id);

      if (response.message === "OK") {
        this.years = response.data;
      }
      this.isYearLoading = false;
    },
    isPaymentVisible(val) {
      this.visible = val;
    },
  },
  computed: {
    ...mapGetters(["activeUser", "getWebView", "getWebViewApp"]),
  },
  watch: {
    "form.marriageStatus"() {
      if (this.form.marriageStatus == 2) {
        if (this.isVisibleStep) {
          this.updateComponents.splice(4, 0, MarriedYearForm);
          this.updateComponents.splice(5, 0, PartnerForm);
          this.updateComponents.splice(6, 0, SignatureFormMarried);
        } else {
          this.components.splice(8, 0, MarriedYearForm);
          this.components.splice(9, 0, PartnerForm);
          this.components.splice(10, 0, SignatureFormMarried);
          this.stepMap = [
            0, 8.3, 16.6, 25, 38, 50, 67, 75, 81.25, 87.5, 90, 93.75, 98, 100,
          ];
        }
      } else if (
        this.isVisibleStep
          ? this.updateComponents.length > 8
          : this.components.length > 12
      ) {
        if (this.isVisibleStep) {
          this.updateComponents.splice(4, 3);
        } else {
          this.components.splice(8, 3);
          this.stepMap = [0, 8.3, 16.6, 25, 50, 67, 75, 87.5, 94, 100];
        }
      }
    },
    "$route.query": {
      deep: true,
      async handler(data, oldData) {
        const step = data.step; //  step: 1
        const oldStep = oldData.step || 0; // step: 0 yoksa default: 0
        if (!isNaN(step)) {
          // ileri gidiyorsa.
          //  old: 2 < new: 3
          if (oldStep < step && !this.routeChange) {
            const valid = await this.$refs.steps.CHECK_STEP();
            // eğer valid true ise active step değiştirilir.
            if (valid) this.activeStep = Number(step);
            // eğer valid false ise eski route pushlanır.
            else this.$router.replace({ query: oldData });
          } else if (
            Number(oldStep) - Number(step) >= -1 ||
            Number(oldStep) - Number(step) <= 1
          ) {
            this.activeStep = Number(step);
            // 1 - 3 || 3 - 1 => Bu durumlarda fazladan step geçileceği için engellenir olddata ile replace yapılır.
          } else {
            this.$router.replace({ query: oldData });
          }
        } else {
          this.activeStep = 0;
        }
      },
    },
    loading(data) {
      if (data) this.routeChange = true;
      else {
        setTimeout(() => {
          this.routeChange = false;
        }, 250);
      }
    },
  },
  created() {
    if (this.activeUser.id) {
      this.isVisibleStep = true;

      this.components = this.updateComponents;
      this.userInfo();
      this.yearsList();
      this.userId = this.activeUser.id;
    }
  },
};
</script>
<style lang="scss" scoped>
.step-page {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  min-height: calc(100vh - 80px);
  // overflow-y: scroll;
}
.component-height {
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.step-action-button {
  margin-top: 4rem;
}

.router-hover {
  // position: fixed;
  &:hover {
    text-decoration: none;
  }
}

@media screen and (max-width: 768px) {
  // .step-page {
  //   min-height: calc(100vh - 80px) !important;
  // }

  // .router-hover {
  //   position: absolute;
  // }
}
</style>
