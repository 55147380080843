<template>
  <div class="text-dark-blue form-style">
    <div class="person-form col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
      <StepTitle :title="$t('partnerInfo')" :activeStep="4" v-if="!isShow" />
      <b-form class="ml-0" :class="isShow ? '' : 'mt-5'">
        <Alert :alert="alert" />
        <div class="row">
          <b-form-group class="col-6">
            <label for="name">{{ $t("name") }}</label>
            <b-form-input
              type="text"
              id="name"
              v-model="$v.form.name.$model"
              :state="!$v.form.name.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.name.required">{{
              $t("validateMessages.pleaseName")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group class="col-6">
            <label for="surname">{{ $t("surname") }}</label>
            <b-form-input
              type="text"
              id="surname"
              v-model="$v.form.surname.$model"
              :state="!$v.form.surname.$error"
            />
            <b-form-invalid-feedback v-if="!$v.form.surname.required">{{
              $t("validateMessages.pleaseSurname")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </div>
        <b-form-group>
          <label for="date">{{ $t("birth") }}</label>
          <!-- <b-form-input
            type="date"
            id="date"
            v-model="$v.form.birthDate.$model"
            :state="!$v.form.birthDate.$error"
            :locale="$i18n.locale"
            :max="minYear"
          /> -->
          <v-date-picker
            v-model="$v.form.birthDate.$model"
            :state="!$v.form.birthDate.$error"
            :locale="$i18n.locale"
            :max-date="dateValidation()"
            :min-date="new Date(1900, 0, 1)"
            :popover="{ visibility: 'click' }"
            is-required
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="form-control"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
          <b-form-invalid-feedback v-if="!$v.form.birthDate.required">{{
            $t("validateMessages.pleaseBirthDate")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <p class="font-weight-bold mt-3">{{ $t("address") }}</p>
        <p class="font-size-14 mb-3">
          {{ $t("addressText") }}
          <span class="font-weight-bold">{{ $t("addressRegistration") }}</span>
          {{ $t("addressGermany") }}
        </p>
        <div class="row align-items-center mb-3 married-form-margin">
          <div class="col-12 col-md-6 col-lg-6">
            <div class="radio-button">
              <input
                type="radio"
                id="sameAddress"
                name="address"
                :value="true"
                v-model="form.addressShow"
              />
              <label for="sameAddress" class="mr-2 w-100">{{
                $t("sameAddress")
              }}</label>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-6">
            <div class="radio-button">
              <input
                type="radio"
                id="differentAddress"
                name="address"
                :value="false"
                v-model="form.addressShow"
              />
              <label for="differentAddress" class="mr-2 w-100">{{
                $t("noSameAddress")
              }}</label>
            </div>
          </div>
        </div>
        <div v-if="!form.addressShow">
          <div class="row">
            <b-form-group class="col-8">
              <label for="street">{{ $t("street") }}</label>
              <b-form-input
                type="text"
                id="street"
                v-model="$v.form.address.street.$model"
                :state="!$v.form.address.street.$error"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.address.street.required"
                >{{
                  $t("validateMessages.pleaseStreet")
                }}</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group class="col-4">
              <label for="apartmentNo">{{ $t("number") }}</label>
              <b-form-input
                type="number"
                id="apartmentNo"
                inputmode="numeric"
                v-model="$v.form.address.apartmentNo.$model"
                :state="!$v.form.address.apartmentNo.$error"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.address.apartmentNo.required"
                >{{
                  $t("validateMessages.pleaseNumber")
                }}</b-form-invalid-feedback
              >
            </b-form-group>
          </div>
          <div class="row">
            <b-form-group class="col-6">
              <label for="code">{{ $t("postCode") }}</label>
              <b-form-input
                type="text"
                id="code"
                v-model="$v.form.address.postalCode.$model"
                :state="!$v.form.address.postalCode.$error"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.address.postalCode.required"
                >{{
                  $t("validateMessages.pleasePostalCode")
                }}</b-form-invalid-feedback
              >
              <b-form-invalid-feedback
                v-if="!$v.form.address.postalCode.minLength"
                >{{
                  $t("validateMessages.minPostalCodeText")
                }}</b-form-invalid-feedback
              >
              <b-form-invalid-feedback
                v-if="!$v.form.address.postalCode.maxLength"
                >{{
                  $t("validateMessages.maxPostalCodeText")
                }}</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group class="col-6">
              <label for="city">{{ $t("city") }}</label>
              <b-form-input
                type="text"
                id="city"
                v-model="$v.form.address.city.$model"
                :state="!$v.form.address.city.$error"
              />
              <b-form-invalid-feedback v-if="!$v.form.address.city.required">{{
                $t("validateMessages.pleaseCity")
              }}</b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>
        <b-form-group class="iban-text">
          <div
            class="d-flex align-items-center optional"
            @mouseleave="showIbanInfo = false"
          >
            <label for="iban"
              >{{ $t("iban") }}
              <font-awesome-icon
                :icon="faInfoCircle"
                @mouseover="() => ibanInfo()"
            /></label>
            <p class="text-muted font-size-14">{{ $t("optional") }}</p>
          </div>
          <div v-if="showIbanInfo" class="document-message">
            {{ $t("ibanInfoText") }}
          </div>
          <b-form-input
            type="text"
            id="iban"
            v-model="$v.form.iban.$model"
            :state="!$v.form.iban.$error"
          />
          <b-form-invalid-feedback v-if="!$v.form.iban.maxLength">{{
            $t("validateMessages.ibanMaxLength")
          }}</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.iban.minLength">{{
            $t("validateMessages.ibanMinLength")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group>
          <div
            class="d-flex align-items-center optional"
            @mouseleave="showTaxIdentifyNo = false"
          >
            <label for="idNo"
              >{{ $t("taxId") }}
              <font-awesome-icon
                :icon="faInfoCircle"
                @mouseover="() => showTaxIdentifyNoInfo()"
            /></label>
            <p class="text-muted font-size-14">{{ $t("optional") }}</p>
          </div>
          <div v-if="showTaxIdentifyNo" class="document-message">
            {{ $t("taxNoText") }}
          </div>
          <b-form-input
            type="text"
            id="idNo"
            v-model="$v.form.taxIdentifyNo.$model"
            :state="!$v.form.taxIdentifyNo.$error"
          />
          <b-form-invalid-feedback v-if="!$v.form.taxIdentifyNo.minLength">{{
            $t("validateMessages.idMinLength")
          }}</b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>
  
  <script>
import StepTitle from "./base/StepTitle.vue";
import { validationMixin } from "vuelidate";
import Alert from "../base/alert/Alert.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const {
  required,
  maxLength,
  minLength,
  requiredIf,
} = require("vuelidate/lib/validators");

export default {
  name: "PartnerForm",
  components: {
    StepTitle,
    Alert,
    FontAwesomeIcon,
  },
  data() {
    return {
      form: {},
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      minYear: "",
      showIbanInfo: false,
      faInfoCircle,
      showTaxIdentifyNo: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
      },
      surname: {
        required,
      },
      birthDate: {
        required,
      },
      iban: {
        maxLength: maxLength(22),
        minLength: minLength(22),
      },
      taxIdentifyNo: {
        minLength: minLength(11),
      },
      address: {
        street: {
          requiredIf: requiredIf(function () {
            return !this.form.addressShow;
          }),
        },
        apartmentNo: {
          requiredIf: requiredIf(function () {
            return !this.form.addressShow;
          }),
        },
        postalCode: {
          requiredIf: requiredIf(function () {
            return !this.form.addressShow;
          }),
          maxLength: maxLength(5),
          minLength: minLength(5),
        },
        city: {
          requiredIf: requiredIf(function () {
            return !this.form.addressShow;
          }),
        },
      },
    },
  },
  methods: {
    showTaxIdentifyNoInfo() {
      this.showTaxIdentifyNo = true;
    },
    ibanInfo() {
      this.showIbanInfo = true;
    },
    ibanValidation(val) {
      return /^([a-zA-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/.test(
        val
      );
    },
    CHECK_STEP() {
      if (this.form.iban != "" && this.form.iban != null) {
        this.$v.form.$touch();
        if (!this.$v.form.$anyError) {
          if (this.ibanValidation(this.form.iban)) {
            return true;
          } else {
            this.alert.show = true;
            this.alert.variant = "danger";
            this.alert.message = "ibanFormat";
          }
        }
      } else {
        this.$v.form.$touch();
        return !this.$v.form.$anyError;
      }

      setTimeout(() => {
        this.alert.show = false;
      }, 2000);
    },
    dateValidation() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!
      var yyyy = today.getFullYear() - 16;

      return new Date(yyyy, mm, dd);
    },
  },
  props: {
    isShow: false,
    userPartner: {
      type: Object,
      default: () => {},
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.form = this.userPartner;
    this.form.iban =
      this.userPartner?.iban == "undefined" ? "" : this.userPartner.iban;

    if (this.isVisibleStep) {
      this.form = {
        ...this.userPartner,
        birthDate: this.userPartner?.birthDate?.split("T")[0],
        iban:
          this.userPartner?.iban == "Undefined" || "undefined" || "UNDEFINED"
            ? ""
            : this.userPartner?.iban,
      };
    }

    if (
      this.form.taxIdentifyNo == "" &&
      this.form.iban == "null" &&
      this.form.iban == "Undefined" &&
      this.form.iban == "undefined" &&
      this.form.iban == "UNDEFINED"
    ) {
      this.form.taxIdentifyNo = "";
      this.form.iban = "";
    }
    this.dateValidation();
  },
};
</script>
  
  <style>
</style>