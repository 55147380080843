<template>
  <div class="text-dark-blue form-style">
    <div
      class="workPlace-form col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3"
    >
      <StepTitle :title="$t('workPlaceName')" :activeStep="8" />
      <p class="font-size-14 mb-5">
        {{ $t("workPlaceQuestion") }}
      </p>
      <b-form class="ml-0 mt-5">
        <b-form-group>
          <label for="companyName">{{ $t("companyName") }}</label>
          <b-form-input
            type="text"
            id="companyName"
            v-model="$v.form.workPlaceAddress.companyName.$model"
            :state="!$v.form.workPlaceAddress.companyName.$error"
          />
          <b-form-invalid-feedback
            v-if="!$v.form.workPlaceAddress.companyName.required"
            >{{
              $t("validateMessages.pleaseCompanyName")
            }}</b-form-invalid-feedback
          >
        </b-form-group>
        <div>
          <div class="row">
            <b-form-group class="col-12 col-lg-8">
              <label for="street">{{ $t("street") }}</label>
              <b-form-input
                type="text"
                id="street"
                v-model="$v.form.workPlaceAddress.street.$model"
                :state="!$v.form.workPlaceAddress.street.$error"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.workPlaceAddress.street.required"
                >{{
                  $t("validateMessages.pleaseStreet")
                }}</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group class="col-12 col-lg-4">
              <label for="apartmentNo">{{ $t("number") }}</label>
              <b-form-input
                type="number"
                id="apartmentNo"
                inputmode="numeric"
                v-model="$v.form.workPlaceAddress.no.$model"
                :state="!$v.form.workPlaceAddress.no.$error"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.workPlaceAddress.no.required"
                >{{
                  $t("validateMessages.pleaseNumber")
                }}</b-form-invalid-feedback
              >
            </b-form-group>
          </div>
          <div class="row">
            <b-form-group class="col-12 col-lg-6">
              <label for="code">{{ $t("postCode") }}</label>
              <b-form-input
                type="text"
                id="code"
                v-model="$v.form.workPlaceAddress.postalCode.$model"
                :state="!$v.form.workPlaceAddress.postalCode.$error"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.workPlaceAddress.postalCode.required"
                >{{
                  $t("validateMessages.pleasePostalCode")
                }}</b-form-invalid-feedback
              >
              <!--- <b-form-invalid-feedback
                v-if="!$v.form.address.postalCode.minLength"
                >{{
                  $t("validateMessages.minPostalCodeText")
                }}</b-form-invalid-feedback
              >
              <b-form-invalid-feedback
                v-if="!$v.form.address.postalCode.maxLength"
                >{{
                  $t("validateMessages.maxPostalCodeText")
                }}</b-form-invalid-feedback
              > -->
            </b-form-group>
            <b-form-group class="col-12 col-lg-6">
              <label for="city">{{ $t("city") }}</label>
              <b-form-input
                type="text"
                id="city"
                v-model="$v.form.workPlaceAddress.city.$model"
                :state="!$v.form.workPlaceAddress.city.$error"
              />
              <b-form-invalid-feedback
                v-if="!$v.form.workPlaceAddress.city.required"
                >{{
                  $t("validateMessages.pleaseCity")
                }}</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group class="col-12 col-lg-12">
              <Checkbox
                @change="
                  form.workPlaceAddress.homeOfficeDayCount > 0
                    ? (form.workPlaceAddress.homeOfficeDayCount = 0)
                    : (form.workPlaceAddress.homeOfficeDayCount = 1)
                "
                :value="
                  form.workPlaceAddress.homeOfficeDayCount > 0 ? true : false
                "
                :label="$t('areyouWorkingAsHomeOffice')"
              ></Checkbox>
            </b-form-group>
            <b-form-group
              class="col-12 col-lg-12"
              v-if="
                form.workPlaceAddress.homeOfficeDayCount > 0 ||
                form.workPlaceAddress.homeOfficeDayCount === ''
              "
            >
              <label for="homeOfficeDayCount">{{
                $t("homeOfficeDayCount")
              }}</label>
              <b-form-input
                type="number"
                :min="0"
                :max="7"
                @blur="
                  CHECK_COUNT(form.workPlaceAddress.homeOfficeDayCount, 0, 7)
                "
                id="homeOfficeDayCount"
                v-model="$v.form.workPlaceAddress.homeOfficeDayCount.$model"
              />
            </b-form-group>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import StepTitle from "./base/StepTitle.vue";
import { validationMixin } from "vuelidate";
import Checkbox from "../base/Checkbox.vue";
const { requiredIf, required } = require("vuelidate/lib/validators");

export default {
  name: "WorkPlaceAddress",
  components: {
    StepTitle,
    Checkbox,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {},
      validateStatus: true,
    };
  },
  methods: {
    CHECK_COUNT(value, min, max) {
      if (value < min) {
        this.form.workPlaceAddress.homeOfficeDayCount = 0;
      } else if (value > max) {
        this.form.workPlaceAddress.homeOfficeDayCount = 7;
      }
    },
    loopForm(obj) {
      let keyName = "";
      for (keyName in obj) {
        let thisObj = obj[keyName];

        if (thisObj.length > 0) {
          this.validateStatus = false;
          break;
        } else {
          this.validateStatus = true;
        }
      }
    },
    CHECK_STEP() {
      this.loopForm(this.form.workPlaceAddress);

      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        return true;
      }
    },
  },
  mounted() {
    this.form = this.data;
  },
  mixins: [validationMixin],
  validations: {
    form: {
      workPlaceAddress: {
        companyName: {
          requiredIf: requiredIf(function () {
            return !this.validateStatus;
          }),
        },
        street: {
          requiredIf: requiredIf(function () {
            return !this.validateStatus;
          }),
        },
        no: {
          requiredIf: requiredIf(function () {
            return !this.validateStatus;
          }),
        },
        postalCode: {
          requiredIf: requiredIf(function () {
            return !this.validateStatus;
          }),
          // maxLength: maxLength(5),
          // minLength: minLength(5),
        },
        city: {
          requiredIf: requiredIf(function () {
            return !this.validateStatus;
          }),
        },
        homeOfficeDayCount: {},
      },
    },
  },
};
</script>

<style></style>
