<template>
  <div class="container">
    <div class="terms-conditions col-12">
      <h6 class="text-blue mb-4">Allgemeine Geschäftsbedingungen (AGB)</h6>
      <p class="mb-4">
        der IHGP STEUER GmbH, Steuerberatungsgesellschaft, Oranienstraße 155
        · 10969 Berlin
      </p>
      <h6 class="text-blue mb-4">Präambel</h6>
      <p class="mb-2">
        Die IHGP STEUER GmbH (nachfolgend: Anbieter) betreibt unter der
        Internetadresse www.flinktax.de eine Plattform (nachfolgend: Flinktax),
        auf der Verbraucher (nachfolgend: Nutzer) ihre Einkommenssteuererklärung
        erstellen können. Die Plattform dient dem einfachen und unkomplizierten
        erstellen von Einkommenssteuererklärungen. Der Nutzer erhält dabei die
        Möglichkeit, seine Einkommensteuer durch Flinktax berechnen und über
        ELSTER, dem digitalen Portal der deutschen Finanzverwaltung, an das
        zuständige Finanzamt des Nutzers senden zu lassen. Der Nutzer erhält
        eine Kopie seiner eingereichten Steuererklärung. Die weitere Abwicklung
        erfolgt direkt zwischen dem Nutzer und dem zuständigen Finanzamt.
      </p>
      <div class="mt-3">
        <ol>
          <li>
            <h6 class="text-dark-blue mb-2">Allgemeines</h6>
            <ol>
              <li>
                <p>
                  Die vorliegenden allgemeinen Geschäftsbedingungen gelten für
                  alle geschäftlichen Beziehungen zwischen dem Anbieter und dem
                  Nutzer. Sie regeln alle Belange im Zusammenhang mit der
                  Nutzung der Plattform und der App künftig (nachfolgend
                  zusammen: Flinktax).
                </p>
                <p>
                  Die Nutzung von Flinktax ist nur für natürliche, unbeschränkt
                  geschäftsfähige Personen zulässig, die das 18. Lebensjahr
                  vollendet haben, da über die Plattform lediglich
                  Einkommenssteuererklärungen abgegeben werden. Bei
                  Registrierung einer juristischen Person behält sich der
                  Anbieter den sofortigen Ausschluss von der Nutzung von
                  Flinktax vor.
                </p>
              </li>
              <li>
                <p>
                  Die vorliegenden AGB gelten ausschließlich. Abweichende,
                  entgegenstehende oder ergänzende Allgemeine
                  Geschäftsbedingungen des Nutzers werden nur dann und insoweit
                  Vertragsbestandteil, als der Anbieter ihrer Geltung
                  ausdrücklich zugestimmt hat. Dieses Zustimmungserfordernis
                  gilt in jedem Fall.
                </p>
              </li>
              <li>
                <p>
                  Im Einzelfall getroffene, individuelle Vereinbarungen mit dem
                  Nutzer (einschließlich Nebenabreden, Ergänzungen und
                  Änderungen) haben in jedem Fall Vorrang vor diesem Vertrag.
                  Nebenabreden könne auch in Schrift- oder Textform getroffen
                  werden.
                </p>
              </li>
              <li>
                <p>
                  Rechtserhebliche Erklärungen und Anzeigen, die nach
                  Vertragsschluss von dem Nutzer dem Anbieter gegenüber
                  abzugeben sind (z.B. Fristsetzungen, Mängelanzeigen, Erklärung
                  von Rücktritt oder Minderung), bedürfen zu ihrer Wirksamkeit
                  der Textform.
                </p>
              </li>
              <li>
                <p>
                  Hinweise auf die Geltung gesetzlicher Vorschriften haben nur
                  klarstellende Bedeutung. Auch ohne eine derartige Klarstellung
                  gelten daher die gesetzlichen Vorschriften, soweit sie in
                  diesen Nutzungsbedingungen nicht unmittelbar abgeändert oder
                  ausdrücklich ausgeschlossen werden.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 class="text-dark-blue mb-2">Registrierung</h6>
            <ol>
              <li>
                <p>
                  Zur Nutzung der über die Plattform angebotenen Dienste legt
                  der Nutzer einen Account an. Der Nutzer ist verpflichtet,
                  wahrheitsgemäße Angaben zu machen und sich nicht als eine
                  andere Person oder ein anderes Unternehmen auszugeben oder
                  einen Namen zu verwenden, für dessen Gebrauch er keine
                  Berechtigung hat. Er verpflichtet sich, nur einen Account
                  anzulegen. Die angegebenen Daten müssen wahrheitsgemäß und
                  vollständig sein. Eine Übertragung des Accounts an Dritte ist
                  dem Nutzer nicht ohne Zustimmung des Anbieters gestattet.
                </p>
              </li>
              <li>
                <p>
                  Der Nutzer ist allein für die Sicherheit seines Passwortes
                  verantwortlich. Eine Weitergabe an Dritte ist nicht gestattet.
                  Ist das Passwort Dritten bekannt geworden, so ist er gehalten,
                  sein Passwort unverzüglich zu ändern.
                </p>
              </li>
              <li>
                <p>
                  Der Nutzer verpflichtet sich, keine Inhalte mit Viren,
                  Trojanern oder sonstigen Programmierungen oder Schadsoftware,
                  die das System des Anbieters schädigen können, zu übermitteln.
                  Bei Zuwiderhandlung kann der Nutzer von der Teilnahme
                  ausgeschlossen werden, wenn er vom Anbieter vorab darüber
                  informiert wurde. Er wird dem Anbieter jedweden Schaden
                  ersetzen, der durch eine etwaige schuldhafte Verletzung der
                  vorstehenden Verpflichtungen entstanden ist.
                </p>
              </li>
              <li>
                <p>
                  Die Kundgabe sowie die Veröffentlichung und Verbreitung von
                  beleidigendem, rassistischem oder vergleichbarem Inhalt sowie
                  politischen oder religiösen Informationsangeboten über diese
                  Plattform in jeglicher Art und Weise ist untersagt. Der Nutzer
                  verpflichtet sich, keine Werbung oder unzutreffende Warnungen
                  vor Viren, Fehlfunktionen und dergleichen verbreiten oder zur
                  Teilnahme an Gewinnspielen, Schneeballsystemen, Kettenbrief-,
                  Pyramidenspiel- und vergleichbaren Aktionen aufzufordern.
                </p>
              </li>
              <li>
                <p>
                  Der Nutzer ist verpflichtet, bei jeder Nutzung der Plattform,
                  die in diesen AGB niedergelegten Regeln einzuhalten und
                  anzuerkennen. Bei einem Verstoß gegen diese Regelungen kann
                  der Nutzer mit sofortiger Wirkung von der Nutzung von Flinktax
                  ausgeschlossen werden.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 class="text-dark-blue mb-2">Vertragsschluss</h6>
            <ol>
              <li>
                <p>
                  Der Vertrag kommt ausschließlich zwischen dem Anbieter und dem
                  Nutzer zustande. Der Vertrag über die Nutzung der Plattform
                  kommt bereits mit erfolgreicher Registrierung und Eröffnung
                  eines Accounts zustande. Die Registrierung gilt nur als
                  erfolgt und der Account erstellt, wenn der Nutzer die
                  2-Faktor-Identifikation erfolgreich durchlaufen hat.
                </p>
              </li>
              <li>
                <p>
                  Nachdem der Nutzer seine Dokumente hochgeladen hat, nimmt der
                  Anbieter lediglich eine Schätzung der Steuerlast oder
                  potenziellen Steuererstattung vor. Es ist wichtig zu betonen,
                  dass diese Berechnung keine Garantie für eine tatsächliche
                  Erstattung darstellt. Bei Zustimmung kann der Nutzer den
                  Anbieter gegen Gebühr beauftragen, die Steuererklärung über
                  ELSTER, dem digitalen Portal der deutschen Finanzverwaltung,
                  an das für ihn zuständige Finanzamt weiterzuleiten.
                </p>
              </li>
              <li>
                <p>
                  Der Vertrag über die Beauftragung zur Einreichung der
                  Steuererklärung zwischen dem Anbieter und dem Nutzer kommt
                  unter Einschluss der vorliegenden ABG zustande, indem der
                  Nutzer das Angebot des Anbieters durch Klicken des Buttons
                  „Jetzt bezahlen“ annimmt. Der Nutzer erhält im Anschluss eine
                  E-Mail mit der Bestätigung der Beauftragung. Der Vertragstext
                  wird vom Anbieter gespeichert und nach dem Login auf der
                  Weboberfläche vom Nutzer eingesehen werden.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 class="text-dark-blue mb-2">Leistungsumfang</h6>
            <ol>
              <li>
                <p>
                  Der Anbieter stellt dem Nutzer eine über das Internet
                  erreichbare und abrufbare Plattform zur Verfügung, auf der der
                  Nutzer einkommenssteuerrelevante Angaben machen, die
                  entsprechenden Dokumente dazu hochladen, sich seine
                  Einkommenssteuerlast berechnen lassen sowie seine
                  Einkommensteuer über ELSTER an das zuständige Finanzamt senden
                  kann. Die Plattform ist für Nutzer aus dem Internet abrufbar.
                </p>
              </li>
              <li>
                <p>
                  Um die Leistungen des Anbieters über die Plattform vollständig
                  nutzen zu können, ist der Nutzer verpflichtet, steuerrelevante
                  Angaben wahrheitsgemäß und vollständig zu machen, insbesondere
                  seine Bankverbindung für mögliche Steuererstattungen oder
                  Einziehung von Steuerschulden zu nennen, seine Identität
                  mittels der vom Anbieter zur Verfügung gestellten Dienste
                  überprüfen zu lassen sowie relevante Daten und Dokumente an
                  den Anbieter zu übermitteln. Außerdem muss der Nutzer dem
                  Anbieter eine Vollmacht erteilen.
                </p>
              </li>
              <li>
                <p>
                  Der genaue Umfang der der Leistung sowie die Funktionalität
                  der Plattform ergeben sich im Übrigen aus den Beschreibungen
                  auf der. Es wird ausdrücklich klargestellt, dass eine
                  Steuerberatung, sonstige rechtliche Beratung sowie Überprüfung
                  des vom Finanzamt erstellten Steuerbescheides nicht Teil der
                  geschuldeten Leistung sind. Bei spezifischen steuerlichen oder
                  rechtlichen Fragen kann sich der Nutzer gerne außerhalb der
                  Plattform an den Anbieter wenden und ein gesondertes Angebot
                  für derartige Leistungen einholen.
                </p>
              </li>
              <li>
                <p>
                  Die vom Anbieter vorgenommene Berechnung ist stets eine
                  Schätzung, die ausschließlich auf den vom Nutzer
                  bereitgestellte Informationen basiert. Der Anbieter übernimmt
                  keine Gewähr, dass seine Schätzung mit der endgültigen
                  Festsetzung der Steuerlast durch das zuständige Finanzamt
                  übereinstimmt.
                </p>
              </li>
              <li>
                <p>
                  Dem Nutzer wird nach Fertigstellung seiner Steuererklärung und
                  Einreichung beim zuständigen Finanzamt eine Kopie seiner
                  Steuererklärung zum Download bereitgestellt. Er erhält zudem
                  eine Bestätigung der Übermittlung an das Finanzamt per E-Mail.
                </p>
              </li>
              <li>
                <p>
                  Es kann vorkommen, dass der Anbieter, auch noch nach Löschung
                  des Accounts des Nutzers, Post vom für den Nutzer zuständigen
                  Finanzamt für den Nutzer erhält. In diesem Fall ist der
                  Anbieter nicht verpflichtet, diese Post an den Nutzer
                  weiterzuleiten, es sei denn, er wurde für den entsprechenden
                  Veranlagungszeitraum mit der steuerlichen Beratung beauftragt.
                </p>
              </li>
              <li>
                <p>
                  Der Anbieter stellt dem Nutzer die Plattform (Webanwendung) in
                  ihrer jeweils aktuellsten Version am Routerausgang des
                  Rechenzentrums, in dem der Server mit der Software steht, dem
                  sog. Übergabepunkt, zur Nutzung bereit. Die Software, die für
                  die Nutzung erforderliche Rechenleistung und die erforderliche
                  Speicherkapazität werden vom Anbieter als vertraglich
                  vereinbarte Leistung bereitgestellt. Der Anbieter schuldet
                  jedoch nicht die Herstellung und Aufrechterhaltung der
                  Datenverbindung zwischen den IT-Systemen des Nutzers und dem
                  Übergabepunkt.
                </p>
              </li>
              <li>
                <p>
                  Der Anbieter behält sich vor, sein Leistungsangebot insoweit
                  zu erweitern oder zu verbessern, wie dies zur Anpassung an die
                  aktuellen technischen Gegebenheiten notwendig ist, um
                  insbesondere die Sicherheit und Stabilität des Services zu
                  sichern oder geänderten gesetzlichen Anforderungen gerecht zu
                  werden. Er verpflichtet sich dazu, solche Änderungen nur in
                  einem für die Nutzer zumutbaren Rahmen und unter
                  Berücksichtigung der Interessen aller Vertragsparteien
                  vorzunehmen.
                </p>
              </li>
              <li>
                <p>
                  Nach anerkanntem Stand der Technik ist es nicht möglich,
                  komplexe Softwareprodukte zu entwickeln, die vollkommen frei
                  von Fehlern sind. Die vereinbarte Beschaffenheit der zur
                  Verfügung gestellten Software (Webanwendung und mobile
                  Anwendung) ist daher nicht darauf gerichtet, dass keinerlei
                  Programmfehler auftreten dürfen, sondern nur darauf, dass die
                  Software keine Programmfehler aufweist, welche die
                  Funktionsfähigkeit des Programms für die nach dem Vertrag
                  vorausgesetzte Verwendung beeinflussen.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 class="text-dark-blue mb-2">Abwicklung</h6>
            <ol>
              <li>
                <p>
                  Um die Leistung für den Nutzer erbringen und die
                  Steuererklärung über ELSTER beim zuständigen Finanzamt
                  einreichen zu können, muss der Nutzer dem Anbieter eine
                  entsprechende Vollmacht erteilen. Die Vollmacht wird durch die
                  Unterschrift des Nutzers auf der Plattform erteilt. Ohne die
                  Erteilung der Vollmacht ist eine Einreichung der
                  Steuererklärung beim Finanzamt nicht möglich. Solange die
                  Vollmacht nicht widerrufen wurde, kann der Anbieter dem Nutzer
                  auch in den Folgejahren basierend auf den bei Finanzbehörden
                  hinterlegten Daten Erinnerungen zur Abgabe eine
                  Steuererklärung senden.
                </p>
              </li>
              <li>
                <p>
                  Zu den vom Nutzer zu machenden notwendigen Angaben gehören:
                  Name, Familienstand, Beschäftigung, Adresse,
                  Religionszugehörigkeit, Beruf, Arbeitgeber,
                  Lohnsteuerbescheinigung, Lohnsteuerbescheinigung,
                  Zweitwohnung, zuständiges Finanzamt,
                  Steueridentifikationsnummer, Aus- und Weiterbildung, Ausgaben
                  für Arbeitsmittel, Bewerbungen, Berufsverbände, Einkünfte aus
                  Kapitalvermögen (wenn ein Bankbeleg vorhanden ist), Einkünfte
                  aus dem Verkauf von Waren (mit Ausnahme von Einkünften aus
                  Pensionsfonds), Versicherungen, Gesundheitsausgaben,
                  Hinterbliebenenabgaben, Behinderungen, Pflegepersonen,
                  persönliche Daten von Kindern, die im selben Haushalt leben,
                  Eltern oder andere Personen, Spenden, Kirchensteuer,
                  Haushaltsausgaben, Ausgaben aus Pensionsfonds, Dauerausgaben,
                  Unterhaltszahlungen an Dritte und Verlustvorträge, soweit
                  zutreffend.
                </p>
              </li>
              <li>
                <p>
                  Diese Angaben hat er, soweit erforderlich und abgefragt durch
                  entsprechende Dokumente zu verifizieren. Der Anbieter
                  verwendet die vom Nutzer gemachten Angaben zur Berechnung der
                  Steuerlast nur, wenn sie jeweils durch die vom Nutzer zur
                  Verfügung gestellten und hochgeladenen Dokumente belegt
                  werden. Der Anbieter kann ggfs. weitere Informationen oder
                  Dokumente vom Nutzer anfordern. Kommt der Nutzer diesem
                  Verlangen nicht nach, hat er keinen Anspruch auf Ausführung
                  der Dienstleistung des Anbieters.
                </p>
              </li>
              <li>
                <p>
                  Die vom Anbieter akzeptierten Dateiformate sind PDF, jpeg und
                  png. Der Anbieter ist zur Erbringung der Dienstleistung nicht
                  verpflichtet, wenn ihm der Nutzer die erforderlichen oder
                  angeforderten Dokumente in einem anderen Format zur Verfügung
                  stellt.
                </p>
              </li>
              <li>
                <p>
                  Zudem hat der Nutzer seine Identität zweifelsfrei
                  nachzuweisen. Hierzu stellt er dem Anbieter folgende
                  Unterlagen/Dokumente bereit, soweit dieser es abfragt:
                  Lohnsteuerbescheinigung, Lohn- /Gehaltsabrechnung,
                  Meldebestätigung, deutscher Personalausweis oder andere
                  Ausweisdokumente. Sollte es erforderlich sein, kann der
                  Anbieter weitere Unterlagen oder Dokumente zur Feststellung
                  oder Überprüfung der Identität vom Nutzer verlangen.
                </p>
              </li>
              <li>
                <p>
                  Der Nutzer ist verpflichtet, seine Informationen, Unterlagen
                  und Dokumente so rechtzeitig vollständig zur Verfügung zu
                  stellen, dass der Anbieter für den jeweiligen
                  Veranlagungszeitraum noch ausreichend Zeit zur Erbringung
                  seiner Dienstleistung und zur Übermittlung der Steuererklärung
                  an das zuständige Finanzamt hat. Spätestens 14 Tage vor dem
                  Ablauf etwaiger Fristen zur Einreichung der Erklärung hat der
                  Nutzer den Anbieter in die Lage zu versetzen, dass dieser die
                  Steuerklärung beim Finanzamt einreichen kann, ohne dass
                  weitere Mitwirkungshandlungen des Nutzers erforderlich sind.
                  Eventuelle Verspätungs- und Versäumniszuschläge, die auf einem
                  Versäumnis des Nutzers beruhen, hat dieser selbst zu tragen.
                </p>
              </li>
              <li>
                <p>
                  Sie sind nur dann zur Steuerdeklaration mit Flinktax
                  berechtigt, sofern Sie für den betreffenden
                  Veranlagungszeitraum nicht bereits einen Angehörigen des
                  steuerberatenden Berufs (Steuerberater, Wirtschaftsprüfer,
                  Rechtsanwalt) mit der Bearbeitung einer entsprechenden
                  Steuererklärung beauftragt haben (nachfolgend
                  „Nutzungsvoraussetzung“ genannt). Die Nutzungsvoraussetzung
                  soll Doppelübermittlungen der Steuererklärung an die
                  Finanzverwaltung vermeiden. Zur Feststellung, ob Sie zur
                  Abgabe der Steuererklärung über Flinktax berechtigt sind,
                  werden Sie bei der Nutzung von Flinktax gefragt, ob diese
                  Nutzungsvoraussetzung gegeben ist („Selbsterklärung“).
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 class="text-dark-blue mb-2">Gewährleistung</h6>
            <ol>
              <li>
                <p>
                  Die Gewährleistung richtet sich nach den gesetzlichen
                  Vorschriften, sofern nicht nachfolgend etwas anderes bestimmt
                  ist. Nach anerkanntem Stand der Technik ist es nicht möglich,
                  komplexe Softwareprodukte zu entwickeln, die vollkommen frei
                  von Fehlern sind. Die vereinbarte Beschaffenheit der zur
                  Verfügung gestellten Software (Webanwendung und mobile
                  Anwendung) ist daher nicht darauf gerichtet, dass keinerlei
                  Programmfehler auftreten dürfen, sondern nur darauf, dass die
                  Plattform keine Programmfehler aufweist, welche die
                  Funktionsfähigkeit der Plattform für die nach dem Vertrag
                  vorausgesetzte Verwendung beeinflussen.
                </p>
              </li>
              <li>
                <p>
                  Der Anbieter ist bemüht, den Zugang zur Plattform rund um die
                  Uhr zur Verfügung zu stellen. Es besteht jedoch kein Anspruch
                  des Nutzers auf ununterbrochene Verfügbarkeit des Services.
                  Der Zugriff auf die Plattform kann gelegentlich unterbrochen
                  oder beschränkt sein, um Instandsetzungen, Wartungen,
                  systemimmanenten Störungen des Internets bei fremden
                  Netzbetreibern, die Einführung von neuen Einrichtungen oder
                  Services zu ermöglichen sowie im Falle höherer Gewalt. Es
                  besteht kein Anspruch auf Leistung oder Schadensersatz des
                  Nutzers, wenn aus den vorstehenden Gründen oder aufgrund
                  höherer Gewalt nicht auf die Plattform zugegriffen werden
                  kann, sofern dem Nutzer nicht tatsächlich ein Schaden
                  entstanden ist.
                </p>
              </li>
              <li>
                <p>
                  Ansprüche aus der Steuererklärung und der damit im
                  Zusammenhang stehenden möglichen Erstattung richten sich
                  ausschließlich gegen das zuständige Finanzamt. Der Anbieter
                  haftet weder für Steuerschulden noch für Steuererstattungen.
                  Er leistet auch keine Gewähr für die Richtigkeit des
                  Steuerbescheides.
                </p>
              </li>
              <li>
                <p>
                  Der Anbieter weist den Nutzer darauf hin, dass Einschränkungen
                  oder Beeinträchtigungen der erbrachten Dienste entstehen
                  können, die außerhalb des Einflussbereichs des Anbieters
                  liegen. Hierunter fallen insbesondere Handlungen von Dritten,
                  die nicht im Auftrag des Anbieters handeln, vom Anbieter nicht
                  beeinflussbare technische Bedingungen des Internets sowie
                  höhere Gewalt. Auch die vom Nutzer genutzte Hard- und Software
                  und technische Infrastruktur kann Einfluss auf die technischen
                  Leistungen des Anbieters haben. Soweit derartige Umstände
                  Einfluss auf die Verfügbarkeit oder Funktionalität der vom
                  Anbieter erbrachten Leistung haben, hat dies keine Auswirkung
                  auf die Vertragsgemäßheit der erbrachten Leistungen.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 class="text-dark-blue mb-2">
              Rechte und Pflichten des Anbieters
            </h6>
            <ol>
              <li>
                <p>
                  Der Anbieter verpflichtet sich, geeignete Vorkehrungen gegen
                  Datenverlust und zur Verhinderung unbefugten Zugriffs Dritter
                  auf die Daten der Nutzer zu treffen. Zu diesem Zweck wird der
                  Anbieter regelmäßige Sicherungen der Daten vornehmen und seine
                  IT-Infrastruktur entsprechend dem allgemeinen Stand der
                  Technik betreiben. Darüber hinaus beachtet der Anbieter die
                  gesetzlichen Bestimmungen zum Datenschutz und zur
                  Datensicherheit und hält diese ein.
                </p>
              </li>
              <li>
                <p>
                  Die vom Nutzer eingebrachten Daten verbleiben währen der
                  gesamten Vertragslaufzeit in seinem Eigentum. Der Anbieter ist
                  nicht für das Einpflegen bzw. Konvertieren von beim Nutzer
                  vorhandenen Daten, Datenbeständen oder Dokumenten
                  verantwortlich. Dies hat der Nutzer selber vorzunehmen.
                </p>
              </li>
              <li>
                <p>
                  Der Anbieter ist zur Sperre des Zugangs des Nutzers
                  berechtigt, wenn der begründete Verdacht besteht, dass die vom
                  Nutzer eingebrachten und gespeicherten Daten und Dokumente
                  rechtswidrig sind und/oder Rechte Dritter verletzen. Ebenso
                  ist der Anbieter berechtigt, den Zugang des Nutzers zu
                  sperren, wenn der begründete Verdacht besteht, dass der Nutzer
                  über steuerlich erhebliche Tatsachen unrichtige oder
                  unvollständige Angaben macht, die Finanzbehörden pflichtwidrig
                  über steuerlich erhebliche Tatsachen in Unkenntnis lässt oder
                  pflichtwidrig die Verwendung von Steuerzeichen oder
                  Steuerstemplern unterlässt und dadurch Steuern verkürzt oder
                  für sich oder einen anderen nicht gerechtfertigte
                  Steuervorteile erlangt (Steuerhinterziehung, § 307 AO). Ein
                  begründeter Verdacht für eine Rechtswidrigkeit und/oder eine
                  Rechtsverletzung liegt insbesondere dann vor, wenn Gerichte,
                  Behörden und/oder sonstige Dritte den Anbieter davon in
                  Kenntnis setzen. Der Anbieter hat den Nutzer von der
                  Entfernung und dem Grund dafür unverzüglich zu verständigen.
                  Die Sperre ist aufzuheben, sobald der Verdacht entkräftet ist.
                </p>
              </li>
              <li>
                <p>
                  Der Anbieter verpflichtet sich gegenüber dem Nutzer zur
                  Verschwiegenheit, sofern sich diese Verpflichtung nicht
                  bereits aus dem Gesetz ergibt. Diese Pflicht bezieht sich auf
                  alles, was der Anbieter im Rahmen der Abwicklung des Vertrages
                  mit dem Nutzer über die Flinktax durch den Nutzer anvertraut
                  oder sonst bekannt wird. Insoweit steht dem Anbieter ggfs. ein
                  Zeugnisverweigerungsrecht zu. Sofern der Anbieter gesetzlich
                  zur Verschwiegenheit verpflichtet ist, darf er über das
                  Bestehen eines Vertrages und Informationen im Zusammenhang mit
                  dem Nutzer sich gegenüber Dritten, insbesondere Behörden,
                  grundsätzlich nur äußern, wenn der Nutzer den Anbieter vorher
                  von seiner Schweigepflicht entbunden hat.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 class="text-dark-blue mb-2">
              Rechte und Pflichten des Nutzers
            </h6>
            <ol>
              <li>
                <p>
                  Dem Nutzer ist es nicht gestattet, Zugangsdaten unbefugt an
                  Dritte herauszugeben. Erlangt ein Dritter Kenntnis von den
                  Zugangsdaten, so hat der Nutzer den Anbieter darüber
                  unverzüglich zu informieren. Der Nutzer hat die ihm durch den
                  Anbieter übermittelten Zugangsdaten geheim zu halten. Er ist
                  bei Verdacht des Missbrauchs durch nicht berechtigte Dritte
                  verpflichtet, dem Anbieter von diesem Verdacht in Kenntnis zu
                  setzen. Der Anbieter ist in diesem Fall berechtigt, die
                  Zugangsberechtigung neu zu verteilen, so z.B. neue Passwörter
                  zu vergeben. Der Nutzer verpflichtet sich zur notwendigen
                  Sorgfalt im Umgang mit seinen Zugangsdaten und zur strikten
                  Geheimhaltung. Der Nutzer steht für jedweden Schaden ein, der
                  dem Anbieter aus einer Verletzung der Pflichten des Nutzers
                  entstanden ist.
                </p>
              </li>
              <li>
                <p>
                  Der Nutzer räumt dem Anbieter für die Zwecke der
                  Vertragsdurchführung das Recht ein, die vom Anbieter für den
                  Nutzer zu speichernden Daten und Dokumente vervielfältigen zu
                  dürfen, soweit dies zur Erbringung der nach diesem Vertrag
                  geschuldeten Leistungen erforderlich ist. Der Anbieter ist
                  auch berechtigt, die Daten und Dokumente in einem
                  Ausfallsystem bzw. separaten Ausfallrechenzentrum vorzuhalten.
                  Zur Beseitigung von Störungen ist der Anbieter ferner
                  berechtigt, Änderungen an der Struktur der Daten oder dem
                  Datenformat vorzunehmen.
                </p>
              </li>
              <li>
                <p>
                  Der Nutzer ist nicht berechtigt, diesen Vertrag mit allen
                  Rechten und Pflichten auf Dritte zu übertragen.
                </p>
              </li>
              <li>
                <p>
                  Der Nutzer ist verpflichtet, über steuerlich erhebliche
                  Tatsachen wahre und vollständige Angaben zu machen. Er darf
                  die Finanzbehörden nicht pflichtwidrig über steuerlich
                  erhebliche Tatsachen in Unkenntnis lassen oder pflichtwidrig
                  die Verwendung von Steuerzeichen oder Steuerstemplern
                  unterlassen und dadurch Steuern verkürzen oder für sich oder
                  einen anderen nicht gerechtfertigte Steuervorteile erlangen
                  (Steuerhinterziehung, § 307 AO).
                </p>
              </li>
              <li>
                <p>
                  Der Nutzer ist selbst dafür verantwortlich, die für den
                  Zugriff auf und die Nutzung der Plattform notwendigen
                  Voraussetzungen bei sich zu schaffen. Dies beinhaltet sowohl
                  das Herstellen einer Internetverbindung zum Übergabepunkt,
                  ggfs. das Vorhandensein entsprechender Internetgeschwindigkeit
                  und Bandbreite als auch das Bereithalten notwendiger Hard- und
                  Software sowie sonstiger IT-Infrastruktur für den Zugriff und
                  die Nutzung der Vertragssoftware.
                </p>
              </li>
              <li>
                <p>
                  Der Nutzer ist des Weiteren verpflichtet, während der
                  Wartungszeiten die Nutzung temporär zu unterlassen, Änderungen
                  an dem Programm in Form von Upgrades und Updates zu
                  akzeptieren, bei Qualitätssicherungsmaßnahmen mitzuwirken und
                  alle für den Betrieb und die Funktionsfähigkeit der PLattform
                  erforderlichen Informationen bereitzustellen.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 class="text-dark-blue mb-2">Kosten und Zahlung</h6>
            <ol>
              <li>
                <p>
                  Alle angegebenen Gebühren und Kosten verstehen sich als
                  Bruttopreise inklusive der gesetzlichen Umsatzsteuer.
                </p>
              </li>
              <li>
                <p>
                  Für die Berechnung der Steuerlast sowie die Einreichung der
                  Steuererklärung zahlt der Nutzer dem Anbieter eine Gebühr in
                  Höhe in von 119,00 EUR. Ab einer berechneten Steuererstattung
                  von mehr als 599,99 EUR beträgt die Gebühr 20 % des zu
                  erwartenden Erstattungsbetrages. Berechnungsgrundlage für die
                  Gebühr ist stets die berechnete, nicht jedoch die tatsächliche
                  Erstattung. Es ist zunächst der Mindestbetrag in Höhe von
                  119,00 EUR (Einzelveranlagung) und 169,00 EUR
                  (Zusammenveranlagung) zu zahlen. Sollte die berechnete
                  Steuererstattung des Nutzers mehr als 599,99 EUR betragen,
                  wird der Nutzer über eine separate E-Mail zur Zahlung der
                  Restsumme aufgefordert.
                </p>
              </li>
              <li>
                <p>
                  Der Mindestbetrag wird fällig, sobald der Nutzer den Vertrag
                  geschlossen hat und den Anbieter mit der Einreichung der
                  Steuererklärung über ELSTER beauftragt hat. Die über den
                  Mindestbetrag hinausgehende Gebühr wird fällig, sobald sie
                  errechnet und dem Nutzer per E-Mail mitgeteilt wurde. Die
                  Zahlung erfolgt über externe Zahlungsdienstleister. Der
                  Anbieter ermöglicht eine Zahlung über PayPal oder Stripe.
                </p>
              </li>
              <li>
                <p>
                  Der Nutzer ist zur Aufrechnung nur berechtigt, wenn seine
                  Gegenforderung rechtskräftig festgestellt oder unbestritten
                  ist oder schriftlich vom Anbieter anerkannt wurde oder es sich
                  um gegenseitig voneinander abhängige Forderungen (Leistung und
                  Gegenleistung) handelt.
                </p>
              </li>
              <li>
                <p>
                  Dem Anbieter steht darüber hinaus ein Zurückbehaltungsrecht
                  zu, wenn der Nutzer nach Mahnung und Nachfristsetzung mit der
                  Zahlung der Gebühren in Verzug kommt. Dies kann zur
                  zwischenzeitlichen Nutzungsbeeinträchtigung von Flinktax
                  führen. Die Geltendmachung des Zurückbehaltungsrechts wird dem
                  Nutzer vorher angekündigt.
                </p>
              </li>
              <li>
                <p>
                  Der Anbieter behält sich das Recht vor, während der
                  Vertragslaufzeit seine Preise sowohl für die Nutzung der
                  Plattform anzupassen. Der Anbieter wird die Preisanpassung nur
                  dann vornehmen, wenn die Anpassung für den Anbieter notwendig
                  ist, um die konkrete Entwicklung der Kosten des Anbieters
                  abzudecken. Dies ist insbesondere bei gestiegenen Personal-
                  und Entwicklungskosten der Fall. Die Preisanpassung wird nach
                  billigem Ermessen erfolgen und nur dann, wenn sie für den
                  Nutzer zumutbar ist. Der Anbieter wird dabei die allgemeine
                  Preisentwicklung der letzten Jahre berücksichtigen.
                  Preisanpassungen werden nach Bekanntgabe mit einer Frist von 3
                  Monaten zum 01. eines Quartals wirksam. Preisanpassungen
                  berechtigen den Nutzer zur Kündigung unter Einhaltung einer
                  Kündigungsfrist von vier Wochen zum Ende des Quartals.
                </p>
              </li>
              <li>
                <p>
                  Sofern der Anbieter sein Leistungsangebot gemäß § 4 dieses
                  Vertrages ändert, erweitert oder ergänzt, ist er berechtigt,
                  das Nutzungsentgelt ebenfalls in angemessener Höhe den
                  vorgenommenen Änderungen der Leistung anzupassen. Die
                  Anpassung des Entgeltes hat der Anbieter dem Nutzer in
                  Textform unter Einhaltung der o.g. Fristen anzukündigen. Die
                  Änderung erfolgt in der Art und in dem Ausmaß, wie der Umfang
                  und die Leistungsfähigkeit vom Anbieter geändert werden. Will
                  der Nutzer den Vertrag nicht zu den geänderten Konditionen
                  fortführen, ist er zur außerordentlichen, Kündigung in
                  Textform mit einer Frist von 14 Tagen zum Änderungszeitpunkt
                  berechtigt.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 class="text-dark-blue mb-2">Dauer und Beendigung</h6>
            <ol>
              <li>
                <p>
                  Der Nutzungsvertrag über die Plattform wird auf unbestimmte
                  Zeit abgeschlossen und kann vom Nutzer jederzeit ohne die
                  Angabe von Gründen mit sofortiger Wirkung gekündigt werden.
                </p>
              </li>
              <li>
                <p>
                  Der Anbieter ist berechtigt, den Nutzugsvertrag ohne Angabe
                  von Gründen mit einer Frist von einem Monat zu kündigen.
                </p>
              </li>
              <li>
                <p>
                  Das Recht zur fristlosen Kündigung aus wichtigem Grund bleibt
                  für beide Vertragsparteien unberührt. Ein wichtiger Grund, der
                  den Anbieter zur Kündigung berechtigt, liegt insbesondere vor,
                  wenn der Nutzer die ihm in diesem Vertrag eingeräumten
                  Nutzungsrechte verletzt oder seinen Pflichten nach diesem
                  Vertrag nicht ordnungsgemäß nachkommt. Dem Anbieter steht
                  darüber hinaus ein Recht zur fristlosen Kündigung zu, wenn der
                  Nutzer mit der Zahlung nach Mahnung und Nachfristsetzung in
                  Verzug gerät.
                </p>
              </li>
              <li>
                <p>
                  Der Nutzer hat nach Beendigung des Vertrages die Nutzung der
                  Plattform unverzüglich aufzugeben und ggfs. sämtliche
                  installierten Kopien aller Programmteile von seinen Rechnern,
                  Geräten und Systemen vollständig zu entfernen. Ggfs. erstellte
                  Sicherungskopien sind dem Anbieter nach seiner Wahl
                  herauszugeben oder vom Nutzer unwiederbringlich zu zerstören.
                </p>
              </li>
              <li>
                <p>
                  Nach Beendigung des Vertrages ist der Anbieter zur Herausgabe
                  sämtlicher Daten des Nutzers verpflichtet. Die
                  Herausgabepflicht erstreckt sich auch auf die ggfs. erstellten
                  Sicherungskopien. Nach erfolgter Herausgabe hat der Anbieter
                  die Daten von seinen Datenträgern unwiderruflich und
                  vollständig zu löschen. Die Herausgabe der Daten erfolgt in
                  einem verkehrsüblichen Format, welches vom Anbieter festgelegt
                  wird.
                </p>
              </li>
              <li>
                <p>
                  Nach Ablauf von drei Monaten nach Beendigung des Vertrages ist
                  der Anbieter auch ohne Zustimmung des Nutzers zur
                  unwiderruflichen und vollständigen Löschung der Daten
                  berechtigt, wenn der Nutzer innerhalb dieses Zeitraumes die
                  Herausgabe der Daten nicht verlangt hat. Der Anbieter hat den
                  Nutzer zuvor jedoch unter Fristsetzung zur Entgegennahme der
                  Daten bzw. zur Erklärung der Zustimmung zur Löschung
                  aufzufordern.
                </p>
              </li>
              <li>
                <p>
                  Der Nutzer bleibt in jedem Fall Alleinberechtigter an den
                  Daten bzw. Dokumenten und kann daher jederzeit, insbesondere
                  nach Beendigung des Vertrages, die Herausgabe einzelner oder
                  sämtlicher Daten verlangen, ohne dass ein
                  Zurückbehaltungsrecht seitens des Anbieters. z.B. wegen
                  Zahlungsrückständen, besteht.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 class="text-dark-blue mb-2">
              Gewerbliche Schutzrechte/Lizenz/Nutzungsrechte
            </h6>
            <ol>
              <li>
                <p>
                  Der Nutzer erhält mit Vertragsbeginn ein nicht
                  ausschließliches, zeitlich auf die Laufzeit dieses Vertrages
                  beschränktes, nicht übertragbares und nicht
                  unterlizensierbares Recht zur Nutzung der Benutzeroberfläche
                  der Plattform-Software sowie zur Anzeige auf dem Bildschirm in
                  den Arbeitsspeicher der vertragsgemäß hierfür verwendeten
                  Endgeräte zu laden und die dabei entstehenden
                  Vervielfältigungen der Benutzeroberfläche im in diesem Vertrag
                  eingeräumten Umfang vorzunehmen.
                </p>
              </li>
              <li>
                <p>
                  Der Nutzer darf die Plattform (Webanwendung) sowie ggfs. zur
                  Verfügung gestellte urheberrechtlich geschützte Werke oder
                  Designs nicht bearbeiten, vervielfältigen, verbreiten, sie
                  drahtgebunden oder drahtlos öffentlich wiederzugeben oder
                  öffentlich zugänglich machen oder in anderer Weise benutzen,
                  sofern dies nicht nach den Vorgaben dieses Vertrages
                  ausdrücklich gestattet ist. Der Nutzer ist nicht berechtigt,
                  diese Software Dritten entgeltlich oder unentgeltlich zur
                  Nutzung zur Verfügung zu stellen.
                </p>
              </li>
              <li>
                <p>
                  Die Vertragssoftware ist urheberrechtlich geschützt. Das
                  Urheberrecht umfasst ebenfalls den Objektcode, die
                  Dokumentation, das Erscheinungsbild, die Struktur und
                  Organisation der Programmdateien, den Programmnamen, Logos und
                  andere Darstellungsformen innerhalb der Software. Der Nutzer
                  ist nicht berechtigt, die Vertragssoftware oder die
                  Benutzerdokumentation, ganz oder teilweise in ursprünglicher
                  oder abgeänderter Form oder in mit anderer Software
                  zusammengemischter oder in anderer Software eingeschlossener
                  Form zu kopieren oder zu vervielfältigen, es sei denn, es ist
                  ausdrücklich nach § 69d Abs. 1 UrhG gestattet und für die
                  bestimmungsgemäße Benutzung des Programms einschließlich der
                  Fehlerberichtigung unbedingt erforderlich.
                </p>
              </li>
              <li>
                <p>
                  Dem Nutzer ist es nicht gestattet, die Software in irgendeiner
                  Form abzuändern oder den Programmcode zurückzuübersetzen
                  (Dekompilierung) und sonstige Rückerschließung der Software
                  durchzuführen oder auf sonstige Weise zu versuchen, den
                  Quellcode zugänglich zu machen außer in der in § 69e UrhG
                  gestatteten Form und für die dort aufgeführten Zwecken. Dies
                  gilt jedoch nur unter der Voraussetzung, dass der Anbieter dem
                  Nutzer die hierzu notwendigen Informationen auf Anforderung
                  nicht innerhalb angemessener Frist zugänglich gemacht hat.
                </p>
              </li>
              <li>
                <p>
                  Wird das Nutzungsrecht gekündigt oder erlischt durch
                  Zeitablauf oder auf andere Weise, so hat der Nutzer die
                  Nutzung der Software einschließlich aller Bestandteile
                  unverzüglich zu unterlassen. Er haftet ggfs. für die
                  fortgesetzte Nutzung durch von ihm beauftragte Dritte.
                </p>
              </li>
              <li>
                <p>
                  Sofern und soweit während der Laufzeit dieses Vertrages,
                  insbesondere durch Zusammenstellung von Anwendungsdaten, durch
                  nach diesem Vertrag erlaubte Tätigkeiten des Nutzers auf den
                  Servern des Anbieters eine Datenbank, Datenbanken, ein
                  Datenbankwerk oder Datenbankwerke entstehen, stehen alle
                  Rechte hieran dem Anbieter zu. Der Anbieter bleibt auch nach
                  Vertragsende Eigentümer der Datenbanken bzw. Datenbankwerke.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 class="text-dark-blue mb-2">Haftung</h6>
            <ol>
              <li>
                <p>
                  Der Anbieter haftet für Vorsatz und grobe Fahrlässigkeit sowie
                  im Falle von Personenschäden uneingeschränkt, für leichte
                  Fahrlässigkeit jedoch nur bei der Verletzung
                  vertragswesentlicher Pflichten, sowie bei vom Anbieter zu
                  vertretender Unmöglichkeit und bei Verzug. Die Haftung ist auf
                  den vertragstypischen Schaden begrenzt, mit dessen Entstehen
                  der Anbieter bei Vertragsschluss auf Grund der zu diesem
                  Zeitpunkt bekannten Umstände rechnen musste. Außerdem haftet
                  er für Schäden uneingeschränkt, für die zwingende gesetzliche
                  Vorschriften, wie z.B. das Produkthaftungsgesetz, eine Haftung
                  vorsehen.
                </p>
              </li>
              <li>
                <p>
                  Für Verlust von Daten haftet der Anbieter nur nach Maßgabe der
                  vorstehenden Absätze und nur dann, wenn ein solcher Verlust
                  durch angemessene Datensicherungsmaßnahmen seitens des Nutzers
                  nicht vermeidbar gewesen wäre.
                </p>
              </li>
              <li>
                <p>
                  Die Haftungsbeschränkungen gelten sinngemäß auch für die
                  Erfüllungsgehilfen des Anbieters.
                </p>
              </li>
              <li>
                <p>
                  Eine weitergehende Haftung des Anbieters besteht nicht,
                  insbesondere haftet er nicht für vom Nutzer bereitgesellte
                  Daten, sofern sich der Anbieter diese nicht durch Weitergabe
                  zu eigen macht.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h6 class="text-dark-blue mb-2">Sonstiges</h6>
            <ol>
              <li>
                <p>
                  Auf Verträge zwischen dem Anbieter und dem Nutzer findet das
                  Recht der Bundesrepublik Deutschland Anwendung, sofern nicht
                  der gewährte Schutz durch zwingende Bestimmungen des Rechts
                  des Staates, in dem der Verbraucher seinen gewöhnlichen
                  Aufenthalt hat, entzogen wird. Die Geltung von UN-Kaufrecht
                  ist ausgeschlossen. Vertragssprache ist ausschließlich
                  Deutsch.
                </p>
              </li>
              <li>
                <p>
                  Sofern der Nutzer in der Bundesrepublik Deutschland keinen
                  allgemeinen Gerichtsstand hat, gilt als Gerichtsstand der Sitz
                  des Anbieters für sämtliche Streitigkeiten als vereinbart, die
                  im Rahmen der Nutzung der Plattform mit dem Anbieter
                  entstehen. Ansonsten gelten die gesetzlichen Bestimmungen zum
                  Gerichtsstand.
                </p>
              </li>
              <li>
                <p>
                  Der Anbieter ist berechtigt, die vorstehenden AGB mit Wirkung
                  für die Zukunft zu ändern, insbesondere um auf eine geänderte
                  Rechtslage oder anderweitige Veränderungen der rechtlichen,
                  technischen oder sonstigen Gegebenheiten zu reagieren. In
                  diesem Fall wird der Anbieter auf seiner Plattform und per
                  E-Mail Änderungen der AGB dem Nutzer mitteilen. Dem Nutzer
                  wird die Gelegenheit eingeräumt, den geänderten AGB binnen
                  vier Wochen zu widersprechen. Erfolgt kein Widerspruch oder
                  wird den Änderungen ausdrücklich zugestimmt, werden die
                  Änderungen zum mitgeteilten Zeitpunkt des Inkrafttretens
                  wirksam. Andernfalls gelten diese AGB unverändert fort. In
                  diesem Fall ist der Anbieter berechtigt, den Vertrag
                  außerordentlich zu kündigen. Der Nutzer wird in der
                  Änderungsmitteilung auf diese Rechtsfolge hingewiesen.
                </p>
              </li>
              <li>
                <p>
                  Sollten einzelne Bestimmungen der vorliegenden AGB ganz oder
                  teilweise unwirksam sein, wird die Wirksamkeit der
                  Nutzungsbedingungen im Übrigen hierdurch nicht berührt.
                  Anstelle der unwirksamen Punkte treten, soweit vorhanden, die
                  gesetzlichen Vorschriften.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </div>
      <h6 class="mb-4 mt-5">Widerrufsbelehrung</h6>
      <h6>Widerrufsrecht</h6>
      <p class="mb-2">
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen
        diesen Vertrag zu widerrufen.
      </p>
      <p class="mb-2">
        Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des
        Vertragsschlusses.
      </p>
      <p class="mb-2">Um Ihr Widerrufsrecht auszuüben, müssen Sie uns, der</p>
      <p class="mb-2">IHGP STEUER GmbH Steuerberatungsgesellschaft</p>
      <p class="mb-2">Oranienstraße 155</p>
      <p class="mb-2">10969 Berlin</p>
      <p class="mb-2">Telefon: +49 30 6110186-0</p>
      <p class="mb-2">Fax: +49 30 6110186-28</p>
      <p class="mb-2">E-Mail: kanzlei@ihgp-steuer.de</p>
      <p>
        mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter
        Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu
        widerrufen, informieren. Sie können dafür das beigefügte
        Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
      </p>
      <p class="mb-5">
        Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung
        über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist
        absenden.
      </p>
      <h6 class="">Folgen des Widerrufs</h6>
      <p class="mb-2">
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
        wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
        Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
        andere Art der Lieferung als die vom Verkäufer angebotene, günstigste
        Standardlieferung gewählt haben), unverzüglich und spätestens binnen
        vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über
        Ihren Widerrufs dieses Vertrages bei uns eingegangen ist. Für die
        Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der
        ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen
        wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen
        wegen dieser Rückzahlung Entgelte berechnet.
      </p>
      <p class="mb-2">
        Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist
        beginnen sollen, so haben Sie uns einen angemessenen Betrag zu zahlen,
        der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung
        des Widerrufsrechts hinsichtlich dieses Vertrages unterrichten, bereits
        erbrachten Leistungen im Vergleich zum Gesamtumfang der im Vertrag
        vorgesehen Leistungen entspricht.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.terms-conditions {
  padding: 160px 0px;
  text-align: justify;

  ol {
    margin-left: 0px !important;
    padding-left: 15px !important;

    li:not(:last-child) {
      margin-bottom: 20px;
    }
    ::marker {
      font-weight: bold;
    }
  }
}
</style>