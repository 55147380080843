<template>
  <div class="text-dark-blue form-style">
    <div class="register-form col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
      <StepTitle :title="$t('registerNow')" :activeStep="1" />
      <!-- <p class="font-weight-bold step-title-text">{{ $t("withMinutes") }}</p> -->
      <b-form class="mt-5">
        <Alert :alert="alert" />
        <b-form-group>
          <label for="email">{{ $t("emailAddress") }}</label>
          <b-form-input
            type="email"
            inputmode="email"
            id="email"
            v-model="$v.form.email.$model"
            :state="!$v.form.email.$error"
          />
          <b-form-invalid-feedback v-if="!$v.form.email.required">{{
            $t("validateMessages.pleaseEmail")
          }}</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.email.email">{{
            $t("validateMessages.pleaseEmailFormat")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="password-icon">
          <label for="password">{{ $t("password") }}</label>
          <b-form-input
            :type="isVisible ? 'password' : 'text'"
            inputmode="password"
            id="password"
            v-model="$v.form.password.$model"
            :state="!$v.form.password.$error"
          />
          <font-awesome-icon
            :icon="isVisible ? faEyeSlash : faEye"
            class="input-icon"
            @click="isVisible = !isVisible"
          />
          <b-form-invalid-feedback v-if="!$v.form.password.required">{{
            $t("validateMessages.pleasePassword")
          }}</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.password.minLength">{{
            $t("validateMessages.pleasePasswordMin")
          }}</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.password.containsUppercase">{{
            $t("validateMessages.passwordFormat")
          }}</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.password.containsLowercase">{{
            $t("validateMessages.passwordFormat")
          }}</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.password.containsNumber">{{
            $t("validateMessages.passwordFormat")
          }}</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.password.containsSpecial">{{
            $t("validateMessages.passwordFormat")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="mt-5" :state="$v.form.terms.$error">
          <b-form-checkbox
            id="checkbox-1"
            name="checkbox-1"
            value="accepted"
            unchecked-value="not_accepted"
            :state="!$v.form.terms.$error"
            v-model="form.terms"
          >
            {{ $t("agreetext") }}
            <label
              ><span
                class="check-link text-dark-blue"
                @click="termsConditionModal = true"
                >{{ $t("agb") }}</span
              ></label
            >
            {{ $t("und") }}
            <label
              ><span
                class="check-link text-dark-blue"
                @click="privacyModal = true"
                >{{ $t("privacyPolicy") }}</span
              ></label
            >
            {{ $t("zu") }}
          </b-form-checkbox>
          <div v-if="$v.form.terms.$error">
            <small class="text-danger">{{ $t("pleaseAcceptTerms") }}</small>
          </div>
        </b-form-group>
        <p class="text-center mt-5">{{ $t("alreadyAccount") }}</p>
        <router-link to="/anmeldung"
          ><p class="text-center font-weight-bold check-link text-dark-blue">
            {{ $t("login") }}
          </p></router-link
        >
      </b-form>
      <b-modal
        centered
        v-model="termsConditionModal"
        hide-footer
        id="terms-condition-modal"
      >
        <TermsAndConditions />
      </b-modal>
      <b-modal centered v-model="privacyModal" hide-footer id="privacy-modal">
        <PrivacyPolicy />
      </b-modal>
    </div>
  </div>
</template>

<script>
import StepTitle from "./base/StepTitle.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { validationMixin } from "vuelidate";
import { controlEmail } from "../../services/modules/User";
import Alert from "../../components/base/alert/Alert.vue";
import TermsAndConditions from "../information/TermsAndConditions.vue";
import PrivacyPolicy from "../information/PrivacyPolicy.vue";

const { required, email, minLength } = require("vuelidate/lib/validators");

export default {
  name: "RegisterForm",
  components: {
    StepTitle,
    FontAwesomeIcon,
    Alert,
    TermsAndConditions,
    PrivacyPolicy,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {},
      faEye,
      isVisible: true,
      faEyeSlash,
      isControl: false,
      privacyModal: false,
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      termsConditionModal: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value);
        },
        containsSpecial: function (value) {
          return /[#?!@$%^&*-.]/.test(value);
        },
      },
      terms: {
        required: (val) => val === "accepted",
      },
    },
  },
  methods: {
    async CHECK_STEP() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        const response = await controlEmail(this.form.email);

        if (response.message === "OK") {
          if (response.data.isExist) {
            this.alert.show = true;
            this.alert.variant = "danger";
            this.alert.message = "validateMessages.emailMatch";
          } else {
            return true;
          }

          setTimeout(() => {
            this.alert.show = false;
          }, 2000);
        }
      }
    },
  },
  mounted() {
    this.form = this.data;
    //  this.alert.show = true;
    //  this.alert.variant = "danger";
    // this.alert.message = "validateMessages.emailPhoneMatch";
  },
};
</script>

<style lang="scss">
#terms-condition-modal,
#privacy-modal {
  .terms-conditions,
  .privacy-policy {
    padding: 60px 0px 0px 0px !important;

    ol,
    ul {
      padding-left: 0;
    }
  }
  .modal-header {
    position: fixed;
    z-index: 999;
    background-color: #57c6ff;
    border-bottom: none;
    border-radius: none;
  }

  .close {
    color: #fff;
    opacity: 1;
  }
  .modal-content {
    max-height: 600px;
    overflow-y: scroll;
  }
  .break-word {
    word-wrap: break-word;
  }
}
</style>