<template>
  <div
    class="text-dark-blue text-center success-message"
  >
    <img src="../../assets/img/congrats.png" alt="congrats" class="mb-3" />
    <h1 class="mb-5">{{ $t("thankYou") }}</h1>
    <div class="col-12 col-md-8 col-lg-8">
      <h3 class="text-blue mb-5">
        {{ $t("relevantInformation") }}
      </h3>
      <h3 class="mb-5">
        {{ $t("taxExpert") }}
      </h3>
      <button class="blue-button" @click="$router.push('/')">
        {{ $t("documentOverview") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuccessMessage",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.success-message {
  min-height: 100vh;
}
</style>