import { getTaxReturn } from "../services/modules/User";

export default {
    methods: {
        async taxReturn(form, sign) {
            const signature = await this.$refs.signature.GET_SIGNATURE();
            form.signatureFile = signature.file;

            const response = await getTaxReturn(form, sign);
            // window.open(response, "_blank");
            return response
        },
    }
}