<template>
  <div :class="drawing ? 'position-fixed-signature' : ''">
    <div class="text-right mb-3 mr-2" style="position: relative">
      <img width="140" class="sig-img" :src="signature" alt="" />
    </div>
    <canvas :key="width" id="sig-canvas" :width="width" height="160">
      Get a better browser, bro.
    </canvas>
    <div
      v-show="started"
      class="text-right mt-2 clear-signature-button"
      style="position: absolute; right: 30px"
    >
      <button id="sig-clearBtn" class="blue-button">
        {{ $t("clearSignature") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      width: 500,
      started: false,
      drawing: false,
      drawingMobile: false,
      signatureTax: false,
    };
  },
  props: {
    signature: {
      default: "",
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hiddenScroll() {
      const html = document.getElementById("hiddenScroll");
      const body = document.getElementById("hiddenScrollBody");
      body.classList.add("hidden-signature");
      body.classList.remove("scroll-signature");
    },
    noHiddenScroll() {
      const html = document.getElementById("hiddenScroll");
      const body = document.getElementById("hiddenScrollBody");
      body.classList.remove("hidden-signature");
      body.classList.add("scroll-signature");
    },
    async GET_SIGNATURE() {
      if (this.signature === "" && !this.started)
        return {
          uri: this.signature,
          file: null,
        };
      else if (this.signature !== "" && !this.started) {
        const signatureFile = await this.getFileFromBase64(
          this.signature,
          "signature-of-user"
        );
        return {
          uri: this.signature,
          file: signatureFile,
        };
      }
      var canvas = document.getElementById("sig-canvas");
      var dataUrl = canvas.toDataURL();
      const signatureFile = await this.getFileFromBase64(
        dataUrl,
        "signature-of-user"
      );
      this.signatureTax = true;
      this.$emit("signatureTax", this.signatureTax);
      return {
        uri: dataUrl,
        file: signatureFile,
      };
    },
    async GET_SIGNATURE_NOT_REQUİRED() {
      var canvas = document.getElementById("sig-canvas");
      var dataUrl = canvas.toDataURL();
      const signatureFile = await this.getFileFromBase64(
        dataUrl,
        "signature-of-user"
      );
      this.signatureTax = true;
      this.$emit("signatureTax", this.signatureTax);
      return {
        uri: dataUrl,
        file: signatureFile,
      };
    },
    RE_INIT_SIGNATURE(width) {
      this.width = width;
      setTimeout(() => {
        this.INIT_SIGNATURE();
      }, 250);
    },
    listenResize() {
      if (window.innerWidth < 600) this.RE_INIT_SIGNATURE(300);
      else this.RE_INIT_SIGNATURE(500);
    },
    INIT_SIGNATURE() {
      const vm = this;
      window.requestAnimFrame = (function (callback) {
        return (
          window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          window.oRequestAnimationFrame ||
          window.msRequestAnimaitonFrame ||
          function (callback) {
            window.setTimeout(callback, 1000 / 60);
          }
        );
      })();

      var canvas = document.getElementById("sig-canvas");
      var ctx = canvas.getContext("2d");
      ctx.strokeStyle = "#222222";
      ctx.lineWidth = 4;

      vm.drawing = false;
      var mousePos = {
        x: 0,
        y: 0,
      };
      var lastPos = mousePos;

      canvas.addEventListener(
        "mousedown",
        function (e) {
          vm.started = true;
          vm.drawing = true;
          // window.onscroll = function () {
          //   window.scrollX(0, 0);
          // };

          lastPos = getMousePos(canvas, e);
        },
        { passive: false }
      );

      canvas.addEventListener(
        "mouseup",
        function (e) {
          vm.drawing = false;
        },
        { passive: false }
      );

      canvas.addEventListener(
        "mousemove",
        function (e) {
          mousePos = getMousePos(canvas, e);
        },
        { passive: false }
      );

      // Add touch event support for mobile
      canvas.addEventListener("touchstart", function (e) {}, false);

      canvas.addEventListener(
        "touchmove",
        function (e) {
          var touch = e.touches[0];
          var me = new MouseEvent("mousemove", {
            clientX: touch.clientX,
            clientY: touch.clientY,
          });
          canvas.dispatchEvent(me);
        },
        { passive: false }
      );

      canvas.addEventListener(
        "touchstart",
        function (e) {
          vm.drawing = true;
          mousePos = getTouchPos(canvas, e);
          var touch = e.touches[0];
          var me = new MouseEvent("mousedown", {
            clientX: touch.clientX,
            clientY: touch.clientY,
          });
          canvas.dispatchEvent(me);
        },
        { passive: false }
      );

      canvas.addEventListener(
        "touchend",
        function (e) {
          vm.drawing = false;
          var me = new MouseEvent("mouseup", {});
          canvas.dispatchEvent(me);
        },
        { passive: false }
      );

      function getMousePos(canvasDom, mouseEvent) {
        var rect = canvasDom.getBoundingClientRect();
        return {
          x: mouseEvent.clientX - rect.left,
          y: mouseEvent.clientY - rect.top,
        };
      }

      function getTouchPos(canvasDom, touchEvent) {
        var rect = canvasDom.getBoundingClientRect();
        return {
          x: touchEvent.touches[0].clientX - rect.left,
          y: touchEvent.touches[0].clientY - rect.top,
        };
      }

      function renderCanvas() {
        if (vm.drawing) {
          ctx.moveTo(lastPos.x, lastPos.y);
          ctx.lineTo(mousePos.x, mousePos.y);
          ctx.stroke();
          lastPos = mousePos;
        }
      }

      // Prevent scrolling when touching the canvas
      document.body.addEventListener(
        "touchstart",
        function (e) {
          if (e.target == canvas) {
            vm.hiddenScroll();
            e.preventDefault();
          }
        },
        { passive: false }
      );
      document.body.addEventListener(
        "touchend",
        function (e) {
          if (e.target == canvas) {
            vm.noHiddenScroll();
            vm.GET_SIGNATURE();
            e.preventDefault();
          }
        },
        { passive: false }
      );
      document.body.addEventListener(
        "touchmove",
        function (e) {
          if (e.target == canvas) {
            e.preventDefault();
          }
        },
        { passive: false }
      );

      (function drawLoop() {
        requestAnimFrame(drawLoop);
        renderCanvas();
      })();

      function clearCanvas() {
        canvas.width = canvas.width;
        vm.started = false;
        vm.signatureTax = false;
        vm.$emit("signatureTax", vm.signatureTax);
      }

      // Set up the UI
      var sigImage = document.getElementById("sig-image");
      var clearBtn = document.getElementById("sig-clearBtn");
      clearBtn.addEventListener(
        "click",
        function (e) {
          clearCanvas();

          sigImage.setAttribute("src", "");
        },
        false
      );
    },
    async getFileFromBase64(base64String, fileName) {
      const data = await fetch(base64String);
      const blob = await data.blob();
      return await new File([blob], fileName, { type: "image/png" });
    },
  },
  mounted() {
    this.listenResize();
    this.GET_SIGNATURE_NOT_REQUİRED();
    // this.hiddenScroll();
  },
  created() {
    window.addEventListener("resize", this.listenResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.listenResize);
  },
  watch: {
    drawing(val) {
      if (val) {
        this.hiddenScroll();
      } else {
        // clearCanvas();
        this.noHiddenScroll();
        this.GET_SIGNATURE();
      }
    },
  },
};
</script>

<style lang="scss">
#sig-canvas,
.sig-img {
  border: 2px dotted #cccccc;
  border-radius: 15px;
  cursor: crosshair;
  background: #f5f2f2;
}
#sig-canvas {
  width: 100%;
}

.hidden-signature {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}
.scroll-signature {
  overflow-x: scroll !important;
  overflow-y: scroll !important;
}

@media screen and (max-width: 767px) {
  canvas {
    width: 100% !important;
  }
}
.position-fixed-signature {
  overflow: hidden !important;
}
</style>