<template>
  <div class="text-dark-blue">
    <div class="married-form col-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
      <StepTitle :title="$t('detailsMaritalStatus')" :activeStep="6" />
      <p class="font-size-14">
        {{ $t("questionText") }}
      </p>
      <div class="mt-5">
        <form>
          <div class="row align-items-center mb-5 married-form-margin">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="radio-button">
                <input
                  type="radio"
                  id="ledig"
                  name="married"
                  value="1"
                  v-model="form.marriageStatus"
                />
                <label for="ledig" class="mr-2 w-100">{{ $t("single") }}</label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="radio-button">
                <input
                  type="radio"
                  id="married"
                  name="married"
                  value="2"
                  v-model="form.marriageStatus"
                />
                <label for="married" class="mr-2 w-100">{{
                  $t("married")
                }}</label>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-12 col-md-6 col-lg-6">
              <div class="radio-button">
                <input
                  type="radio"
                  id="divorced"
                  name="married"
                  value="3"
                  v-model="form.marriageStatus"
                />
                <label for="divorced" class="mr-2 w-100">{{
                  $t("divorced")
                }}</label>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-6">
              <div class="radio-button">
                <input
                  type="radio"
                  id="widow"
                  name="married"
                  value="4"
                  v-model="form.marriageStatus"
                />
                <label for="widow" class="mr-2 w-100">{{
                  $t("widowed")
                }}</label>
              </div>
            </div>
          </div>
          <div
            class="d-flex align-items-center married-select-position"
            @mouseleave="showInfo = false"
          >
            <b-form-group
              class="married-select"
              v-if="form.marriageStatus == 2"
            >
              <b-form-checkbox
                id="status"
                name="status"
                :value="true"
                v-model="form.isPartnerPayment"
              >
                <label for="status">
                  {{ $t("marriedStatusText") }}
                  <font-awesome-icon
                    :icon="faInfoCircle"
                    class="ml-4"
                    @mouseover="showInfo = true"
                  />
                </label>
              </b-form-checkbox>
              <div class="document-message" v-if="showInfo">
              <div>  {{ $t("marriedStatusInfoTitle") }}:</div>
              <div>  {{ $t("marriedStatusInfoText") }}</div>
              <div>  {{ $t("marriedStatusInfoText2") }}</div>
              <div>  {{ $t("marriedStatusInfoText3") }}</div>
              </div>
            </b-form-group>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import StepTitle from "./base/StepTitle.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "MarriedStatusForm",
  components: {
    StepTitle,
    FontAwesomeIcon,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      checked: false,
      form: {},
      faInfoCircle,
      showInfo: false,
    };
  },
  methods: {
    CHECK_STEP() {
      return true;
    },
  },
  watch: {
    "form.marriageStatus"(val) {
      if (val != 2) {
        this.form.isPartnerPayment = false;
      }
    },
  },
  mounted() {
    this.form = this.data;
  },
};
</script>

<style>
</style>