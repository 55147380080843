<template>
  <div class="text-dark-blue form-style">
    <div class="children-form col-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2">
      <StepTitle :title="$t('childrenQuestion')" :activeStep="8" />
      <Alert :alert="alert" />
      <div class="row align-items-center mb-5 married-form-margin mt-5">
        <div class="col-12 col-md-6 col-lg-6">
          <div class="radio-button">
            <input
              type="radio"
              id="married"
              name="marriedStatus"
              :value="true"
              v-model="form.childrenStatus"
            />
            <label for="married" class="mr-2 w-100">{{ $t("yes") }}</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div class="radio-button">
            <input
              type="radio"
              id="unmarried"
              name="marriedStatus"
              :value="false"
              v-model="form.childrenStatus"
            />
            <label for="unmarried" class="mr-2 w-100">{{ $t("no") }}</label>
          </div>
        </div>
      </div>
      <div v-if="form.userChilds?.length > 0" class="p-3">
        <div
          class="children-content"
          v-for="(item, index) in form.userChilds"
          :key="index"
        >
          <div class="row align-items-center justify-content-between">
            <h6 class="text-blue">{{ index + 1 }}.{{ $t("child") }}</h6>
            <font-awesome-icon
              :icon="faXmark"
              @click="() => deleteChildren(index)"
              style="cursor: pointer"
            />
          </div>
          <div class="row">
            <div class="col-12 col-lg-6">
              <b-form-group>
                <label for="name">{{ $t("name") }}</label>
                <b-form-input
                  type="text"
                  inputmode="text"
                  id="name"
                  v-model="item.name"
                />
              </b-form-group>
            </div>
            <div class="col-12 col-lg-6">
              <b-form-group>
                <label for="surname">{{ $t("surname") }}</label>
                <b-form-input
                  type="text"
                  inputmode="text"
                  id="surname"
                  v-model="item.surname"
                />
              </b-form-group>
            </div>
          </div>
          <div>
            <b-form-group>
              <label>{{ $t("birth") }}</label>
              <v-date-picker
                v-model="item.birthDate"
                :locale="$i18n.locale"
                :max-date="new Date()"
                :min-date="new Date(1900, 0, 1)"
                is-required
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="form-control"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </v-date-picker>
              <!-- <b-form-invalid-feedback v-if="!$v.form.birthDate.required">{{
            $t("validateMessages.pleaseBirthDate")
          }}</b-form-invalid-feedback> -->
            </b-form-group>
          </div>
        </div>
      </div>
      <button
        class="blue-button mb-3"
        v-if="form.childrenStatus"
        @click="addChildren"
      >
        {{ $t("addChildren") }}
      </button>
    </div>
  </div>
</template>
  
  <script>
import StepTitle from "./base/StepTitle.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Alert from "../base/alert/Alert.vue";
// import { validationMixin } from "vuelidate";

// const { required } = require("vuelidate/lib/validators");

export default {
  name: "ChildrenForm",
  components: {
    StepTitle,
    FontAwesomeIcon,
    Alert,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {},
      faXmark,
      children: {
        name: "",
        surname: "",
        birthDate: "",
      },
      alert: {
        show: false,
        variant: "",
        message: "",
      },
    };
  },
  methods: {
    addChildren() {
      this.form.userChilds.push({
        ...this.children,
      });
    },
    deleteChildren(index) {
      this.form.userChilds.splice(index, 1);
    },
    requiredChildren(value) {
      return value.name == "" || value.surname == "" || value.birthDate == "";
    },
    async CHECK_STEP() {
      if (this.form.childrenStatus) {
        if (this.form.userChilds.length == 0) {
          this.alert.show = true;
          this.alert.variant = "danger";
          this.alert.message = "notAddChildren";
        } else {
          if (this.form.userChilds.some(this.requiredChildren)) {
            this.alert.show = true;
            this.alert.variant = "danger";
            this.alert.message = "informationCompltely";

            setTimeout(() => {
              this.alert.show = false;
            }, 3000);
            return false;
          } else {
            return true;
          }
        }
      } else {
        return true;
      }

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
    },
  },
  watch: {
    "form.childrenStatus"(val) {
      if (!val) {
        this.form.userChilds = [];
      }
    },
  },
  mounted() {
    this.form = this.data;
  },
};
</script>
  
  <style>
</style>