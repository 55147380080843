<template>
  <div class="text-dark-blue">
    <div
      class="documents-form col-12 col-md-8 offset-md-2 col-lg-8 offset-lg-2"
    >
      <StepTitle :title="$t('uploadTaxDocuments')" :activeStep="9" />
      <Alert :alert="alert" />
      <div class="alert alert-info mt-4" v-if="form.isPartnerPayment">
        {{ $t("dontForgetSpousesTax") }}
      </div>
      <div class="row mt-5">
        <div class="col-12 col-md-6 col-lg-6 mobile-bottom">
          <div
            v-for="(item, i) in documents.filter((d) => d.step === 1)"
            :key="i"
            class="download-box"
            :class="{ filled: item.files.length > 0 }"
            @click="activeDocument = item"
            v-b-modal.modal-document
            @mouseleave="() => documentMouseLeave(item)"
          >
            <font-awesome-icon
              :icon="faInfoCircle"
              @mouseover="() => documentMouseOver(item)"
            />
            <div
              class="document-message"
              v-if="isMessageShow[item.id] && item.info.length != 0"
            >
              <p class="font-size-12">{{ $t(item.info) }}</p>
            </div>
            <p class="w-100 ml-2">{{ $t(item.title) }}</p>
            <font-awesome-icon
              :icon="item.files.length > 0 ? faCheck : faArrowUpFromBracket"
            />
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
          <div
            class="download-box"
            :class="{ filled: item.files.length > 0 }"
            v-for="(item, i) in documents.filter((d) => d.step === 2)"
            :key="i"
            @click="activeDocument = item"
            v-b-modal.modal-document
            @mouseleave="() => documentMouseLeave(item)"
          >
            <font-awesome-icon
              :icon="faInfoCircle"
              @mouseover="() => documentMouseOver(item)"
            />
            <div
              class="document-message"
              v-if="isMessageShow[item.id] && item.info.length != 0"
            >
              <p class="font-size-12 w-100">
                {{ $t(item.info) }}
              </p>
            </div>
            <p class="w-100 ml-2">{{ $t(item.title) }}</p>
            <font-awesome-icon
              :icon="item.files.length > 0 ? faCheck : faArrowUpFromBracket"
            />
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-document"
      centered
      class="text-dark-blue"
      hide-footer
      hide-header
      :no-close-on-backdrop="true"
    >
      <Alert :alert="alert" />
      <div class="d-flex justify-content-between align-items-center w-100">
        <p class="font-weight-bold font-size-13 mb-2">
          {{ $t(activeDocument.title) }}
        </p>
        <font-awesome-icon
          :icon="faXmark"
          class="close-button"
          @click="closeModal"
          v-if="!loading"
        />
      </div>
      <input
        @change="getFileFromInput"
        type="file"
        name="imageUpload"
        id="imageUpload"
        multiple
        class="hide"
        accept="image/*,.pdf,.doc,.docx"
      />
      <label
        @dragover.prevent
        @drop.prevent="getFileFromDrop"
        for="imageUpload"
        class="document-download-box col-12"
      >
        <font-awesome-icon :icon="faArrowUpFromBracket" />
        <p>{{ $t("selectUploadDocument") }}</p>
        <!-- <p class="mt-4">{{ $t("maxiumumLimit") }}</p> -->
      </label>
      <b-form-textarea
        id="description"
        :placeholder="$t('uploadDocumentNote')"
        rows="5"
        max-rows="8"
        maxlength="100"
        class="font-size-12"
        v-model="activeDocument.note"
      ></b-form-textarea>
      <div v-if="!loading">
        <div
          v-for="(file, i) in activeDocument.files"
          :key="i"
          class="d-flex my-1 border-bottom justify-content-between align-items-center w-100"
          style="overflow-y: scroll"
        >
          <div>
            <div class="u-line-1">
              <small> {{ file.name }}</small>
            </div>
            <div>
              <small
                ><i>{{ (file.size / 1000000).toFixed(2) }} MB</i></small
              >
            </div>
          </div>
          <button
            class="blue-button bg-danger"
            type="button"
            @click="() => deleteFiles(i, activeDocument, file)"
          >
            <font-awesome-icon :icon="faTimes" v-if="!deleteLoading[i]" />
            <b-spinner variant="light" v-else></b-spinner>
          </button>
        </div>
      </div>
      <div class="text-center w-100 h-100" v-else>
        <b-spinner variant="primary"></b-spinner>
      </div>
      <div class="text-center">
        <button
          class="blue-button"
          @click="() => addDocumentNote()"
          v-if="!loading"
        >
          <span>{{ $t("confirm") }}</span>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import StepTitle from "./base/StepTitle.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faArrowUpFromBracket,
  faTimes,
  faCheck,
  faInfoCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  sendDocument,
  deleteDocumentFile,
  getUserInfo,
  sendDocumentNote,
  updateDocumentNote,
} from "../../services/modules/User";
import Alert from "../base/alert/Alert.vue";

export default {
  name: "DocumentForm",
  components: {
    StepTitle,
    FontAwesomeIcon,
    Alert,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: Number,
      default: null,
    },
    documentsFiles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      faArrowUpFromBracket,
      faTimes,
      faCheck,
      faXmark,
      faInfoCircle,
      activeDocument: {
        title: "",
        index: 0,
      },
      form: {},
      documents: [],
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      loading: false,
      deleteLoading: [],
      isDocuments: false,
      isMessageShow: [],
    };
  },
  methods: {
    async addDocumentNote() {
      if (this.activeDocument.note != "") {
        if (this.activeDocument.files.length === 0) {
          this.alert.show = true;
          this.alert.variant = "danger";
          this.alert.message = "uploadDocumentNoteError";
        } else {
          const response = this.activeDocument.noteStatus
            ? await updateDocumentNote(
                this.activeDocument.note,
                this.activeDocument.value,
                this.userId,
                this.form.taxYear
              )
            : await sendDocumentNote(
                this.activeDocument.note,
                this.activeDocument.value,
                this.userId,
                this.form.taxYear
              );

          if (response.message === "OK") {
            this.activeDocument.noteStatus = true;
            this.$bvModal.hide("modal-document");
          }
        }

        setTimeout(() => {
          this.alert.show = false;
        }, 3000);
      } else {
        this.$bvModal.hide("modal-document");
      }
    },
    documentMouseOver(item) {
      this.$set(this.isMessageShow, item.id, 1);
    },
    documentMouseLeave(item) {
      this.$set(this.isMessageShow, item.id, 0);
    },
    CHECK_STEP() {
      this.isDocuments =
        this.documents.filter((doc) => doc.files.length > 0).length > 0;

      if (!this.isDocuments) {
        this.alert.show = true;
        this.alert.variant = "danger";
        this.alert.message = "validateMessages.documentRequired";
      }

      setTimeout(() => {
        this.alert.show = false;
      }, 3000);
      return this.isDocuments;
    },
    getFileFromInput(e) {
      this.setFiles(e.target.files);
      this.sendUserDocument(e.target.files[0], this.activeDocument, e);
    },
    getFileFromDrop(e) {
      this.setFiles(e.dataTransfer.files);
      this.sendUserDocument(e.dataTransfer.files[0], this.activeDocument, e);
    },
    setFiles(files) {
      const index = this.documents.findIndex(
        (d) => d.title === this.activeDocument.title
      );
      this.documents[index].files = [...this.documents[index].files, ...files];
    },
    async deleteFiles(val, activeDocument, file) {
      // this.deleteLoading = true;
      this.loading = true;
      this.$set(this.deleteLoading, val, 1);
      const response = await deleteDocumentFile(
        activeDocument.value,
        file,
        this.userId,
        this.form.taxYear
      );

      if (response.message === "OK") {
        this.alert.show = true;
        this.alert.variant = "success";
        this.alert.message = "deleteDocument";
        this.activeDocument.files.splice(val, 1);
      } else {
        this.alert.show = true;
        this.alert.variant = "danger";
        this.alert.message = "noDeleteDocument";
      }
      // this.deleteLoading = false;
      this.$set(this.deleteLoading, val, 0);
      this.loading = false;

      setTimeout(() => {
        this.alert.show = false;
      }, 2000);
    },
    async sendUserDocument(val, document, e) {
      this.loading = true;
      const response = await sendDocument(
        this.activeDocument.files,
        document.value,
        this.userId,
        this.form.taxYear
      );
      if (response.message === "OK") {
        this.alert.show = true;
        this.alert.variant = "success";
        this.alert.message = "validateMessages.downloadDoc";
      } else {
        this.activeDocument.files = [];
        this.alert.show = true;
        this.alert.variant = "danger";
        this.alert.message = "validateMessages.notDownloadDoc";
      }
      setTimeout(() => {
        this.alert.show = false;
      }, 2000);

      this.loading = false;
    },
    async stepUserInfo() {
      const response = await getUserInfo(this.$route.query.userId);

      if (response.message === "OK") {
        this.form = this.response.data;
      }
    },
    closeModal() {
      this.$bvModal.hide("modal-document");
    },
  },
  mounted() {
    this.documents = this.documentsFiles;
    this.form = this.data;
    // this.stepUserInfo()
  },
};
</script>

<style lang="scss" scoped>
.close-button {
  cursor: pointer;
}
.form-control {
  &:focus {
    box-shadow: none !important;
    border-color: #57c6ff;
  }
}
</style>
